import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompaniesComponent } from './companies/companies.component';
import { UsersComponent } from './users/users.component';
import { ProductsComponent } from './products/products.component';
import { StockTakesComponent } from './stock-takes/stock-takes.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { ImportProductsComponent } from './products/import-products/import-products.component';
import { StockTakeDetailsComponent } from './stock-takes/stock-take-details/stock-take-details.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { ScanStockComponent } from './stock-takes/stock-take-details/scan-stock/scan-stock.component';
import { CompareStockTakeComponent } from './stock-takes/stock-take-details/compare-stock-take/compare-stock-take.component';
import { ReceivePurchaseOrderComponent } from './purchase-orders/receive-purchase-order/receive-purchase-order.component';
import { ViewPurchaseOrderComponent } from './purchase-orders/view-purchase-order/view-purchase-order.component';
import { PrintReceivedPurchaseOrderComponent } from './purchase-orders/view-purchase-order/print-received-purchase-order/print-received-purchase-order.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },

  { path: '', component: DashboardComponent, pathMatch: 'full' },
  { path: 'companies', component: CompaniesComponent },
  { path: 'users', component: UsersComponent },

  { path: 'products', component: ProductsComponent },
  { path: 'products/import', component: ImportProductsComponent },

  { path: 'stock-takes', component: StockTakesComponent },
  { path: 'stock-takes/:id', component: StockTakeDetailsComponent },
  { path: 'stock-takes/:id/scan-stock/:areaId', component: ScanStockComponent },
  { path: 'stock-takes/:id/compare', component: CompareStockTakeComponent },

  { path: 'purchase-orders', component: PurchaseOrdersComponent },
  { path: 'purchase-orders/:id/receive', component: ReceivePurchaseOrderComponent },
  { path: 'purchase-orders/:id/view', component: ViewPurchaseOrderComponent },
  { path: 'purchase-orders/:id/print', component: PrintReceivedPurchaseOrderComponent },

  { path: 'profile', component: ProfileComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
