<div id="print-container">
  <h3 *ngIf="!loadingPurchaseOrder && purchaseOrder">PO #{{ purchaseOrder.purchase_order_number }} - {{ purchaseOrder.date_ordered }} ({{ purchaseOrder.supplier }})</h3>
  <h4 *ngIf="!loadingPurchaseOrder && purchaseOrder" class="text-thin">Received by {{ purchaseOrder.user.name }} ({{ purchaseOrder.user.email }}) - {{ purchaseOrder.received }}</h4>
  <p-card *ngIf="!loadingPurchaseOrder">
    <h3>Correctly received</h3>
    <p-messages severity="warn" *ngIf="!correct.length">
      <ng-template pTemplate>
        No products were received correctly
      </ng-template>
    </p-messages>
    <p-table [value]="correct" *ngIf="correct.length">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th style="width: 40%">Product</th>
          <th>SKU</th>
          <th class="text-right">Ordered</th>
          <th class="text-right">Received</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td class="text-right">{{ product.quantity_ordered }}</td>
          <td class="text-right">{{ product.quantity_received }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <p-divider *ngIf="!loadingPurchaseOrder"></p-divider>
  <p-card *ngIf="!loadingPurchaseOrder">
    <h3>Too few received</h3>
    <p-messages severity="warn" *ngIf="!tooLow.length">
      <ng-template pTemplate>
        No products were received less than what was ordered
      </ng-template>
    </p-messages>
    <p-table [value]="tooLow" *ngIf="tooLow.length">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th style="width: 40%">Product</th>
          <th>SKU</th>
          <th class="text-right">Ordered</th>
          <th class="text-right">Received</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td class="text-right">{{ product.quantity_ordered }}</td>
          <td class="text-right">{{ product.quantity_received }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <p-divider *ngIf="!loadingPurchaseOrder"></p-divider>
  <p-card *ngIf="!loadingPurchaseOrder">
    <h3>Too much received</h3>
    <p-messages severity="warn" *ngIf="!tooHigh.length">
      <ng-template pTemplate>
        No products were received more than what was ordered
      </ng-template>
    </p-messages>
    <p-table [value]="tooHigh" *ngIf="tooHigh.length">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th style="width: 40%">Product</th>
          <th>SKU</th>
          <th class="text-right">Ordered</th>
          <th class="text-right">Received</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td class="text-right">{{ product.quantity_ordered }}</td>
          <td class="text-right">{{ product.quantity_received }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <p-divider *ngIf="!loadingPurchaseOrder"></p-divider>
  <p-card *ngIf="!loadingPurchaseOrder">
    <h3>Not received</h3>
    <p-messages severity="warn" *ngIf="!notReceived.length">
      <ng-template pTemplate>
        All products were received
      </ng-template>
    </p-messages>
    <p-table [value]="notReceived" *ngIf="notReceived.length">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th style="width: 40%">Product</th>
          <th>SKU</th>
          <th class="text-right">Ordered</th>
          <th class="text-right">Received</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td class="text-right">{{ product.quantity_ordered }}</td>
          <td class="text-right">{{ product.quantity_received }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <p-divider *ngIf="!loadingPurchaseOrder"></p-divider>
  <p-card *ngIf="!loadingPurchaseOrder">
    <h3>Not ordered</h3>
    <p-messages severity="warn" *ngIf="!notOrdered.length">
      <ng-template pTemplate>
        All products were received that wasn't ordered
      </ng-template>
    </p-messages>
    <p-table [value]="notOrdered" *ngIf="notOrdered.length">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th style="width: 40%">Product</th>
          <th>SKU</th>
          <th class="text-right">Ordered</th>
          <th class="text-right">Received</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td class="text-right">{{ product.quantity_ordered }}</td>
          <td class="text-right">{{ product.quantity_received }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <p-divider *ngIf="!loadingPurchaseOrder"></p-divider>
  <p-card *ngIf="!loadingPurchaseOrder">
    <h3>Not found on system</h3>
    <p-messages severity="warn" *ngIf="!notFound.length">
      <ng-template pTemplate>
        All products were found on system
      </ng-template>
    </p-messages>
    <p-table [value]="notFound" *ngIf="notFound.length">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th style="width: 40%">Product</th>
          <th>SKU</th>
          <th class="text-right">Ordered</th>
          <th class="text-right">Received</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td class="text-right">{{ product.quantity_ordered }}</td>
          <td class="text-right">{{ product.quantity_received }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-blockUI [blocked]="checkIfLoggedIn">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
