import { Component, Input, OnInit } from '@angular/core';
import { StockAreaModel } from '../../../models/stock-area.model';
import { HttpService } from '../../../services/http.service';
import { StockProductModel } from '../../../models/stock-product.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-stock-area',
  templateUrl: './stock-area.component.html',
  styleUrls: ['./stock-area.component.css']
})
export class StockAreaComponent implements OnInit {

  @Input() stockArea: StockAreaModel;
  @Input() stockTakeStatus: boolean;

  isLoadingProducts = true;

  products: StockProductModel[] = [];
  consolidatedProducts: StockProductModel[] = [];

  constructor(
    private httpService: HttpService,
    private messageService: MessageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.httpService.getStockAreaProducts(this.stockArea.id).then(result => {
      this.products = result;
      this.consolidateProducts().then(() => {
        this.isLoadingProducts = false;
      });
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to load stock products', detail: err.error.detail, life: 5000 });
    });
  }

  async consolidateProducts(): Promise<void> {
    for (const product of this.products) {
      if (!(await this.productIdInConsolidated(product))) {
        product.quantity = 1;
        this.consolidatedProducts.push(product);
      }
      else {
        await this.addConsolidatedProductQuantity(product);
      }
    }
  }

  async productIdInConsolidated(product: StockProductModel): Promise<boolean> {
    for (const p of this.consolidatedProducts) {
      if (p.barcode === product.barcode && product.barcode !== '') {
        return true;
      }
      else if (product.barcode === '' && p.stock_code === product.stock_code && product.stock_code !== '') {
        return true;
      }
    }
    return false;
  }

  async addConsolidatedProductQuantity(product: StockProductModel): Promise<void> {
    for (const p of this.consolidatedProducts) {
      if (p.barcode === product.barcode && product.barcode !== '') {
        p.quantity += 1;
      }
      else if (product.barcode === '' && p.stock_code === product.stock_code && product.stock_code !== '') {
        p.quantity += 1;
      }
    }
  }

}
