import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../navigation/menu-item.model';
import { UserModel } from '../models/user.model';
import { CompanyModel } from '../models/company.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../services/http.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddCompanyDialogComponent } from './add-company-dialog/add-company-dialog.component';
import { EditCompanyDialogComponent } from './edit-company-dialog/edit-company-dialog.component';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingCompanies = true;

  companies: CompanyModel[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private httpService: HttpService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/companies'], icon: 'pi pi-map-marker', label: 'Companies' },
            { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
          ];

          this.httpService.getCompanies().then(companies => {
            this.companies = companies;
            this.loadingCompanies = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch companies', detail: err.error.detail, life: 5000 });
          });
        }
        else {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  showAddDialog(): void {
    const dialogRef = this.dialogService.open(AddCompanyDialogComponent, {
      header: 'Add company',
      width: '50%'
    });
    dialogRef.onClose.subscribe((company: CompanyModel) => {
      if (company) {
        this.companies = [...this.companies, company];
      }
    });
  }

  showEditDialog(company: CompanyModel): void {
    const dialogRef = this.dialogService.open(EditCompanyDialogComponent, {
      data: { company },
      header: 'Edit company',
      width: '50%'
    });
  }

  deleteCompany(id: number, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this company?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingCompanies = true;
        this.httpService.deleteCompany(id).then(() => {
          this.httpService.getCompanies().then(companies => {
            this.companies = companies;
            this.loadingCompanies = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch companies', detail: err.error.detail, life: 5000 });
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Couldn\'t delete company', detail: err.error.detail, life: 5000 });
        });
      }
    });
  }

}
