import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import { AuthService } from './auth.service';
import { MessageService } from 'primeng/api';
import { HttpService } from './http.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  isLoggingOutRemotely = false;
  isLoggedInRemotely = false;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private httpService: HttpService,
    private cookieService: CookieService
  ) { }

  validateEmail(email: string): boolean {
    return /^[a-zA-Z0-9.-_]+(\.[a-zA-Z0-9.-_]+)?@[a-zA-Z0-9.-_]+(\.[a-zA-Z0-9.-_]+)?\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?$/.test(email);
  }

  isRemotelyLoggedIn(): boolean {
    if (this.cookieService.getCookie('adstockUser') === null) {
      return false;
    }
    return typeof this.httpService.authAdmin !== 'undefined' && this.httpService.authAdmin !== null;
  }

  getRemoteName(): string {
    if (this.cookieService.getCookie('adstockUser') === null) {
      return '';
    }
    const currentUser: UserModel = JSON.parse(this.cookieService.getCookie('adstockUser'));
    return currentUser.name;
  }

  remoteLogout(): void {
    this.isLoggingOutRemotely = true;
    this.authService.remoteLogout().then(result => {
      this.cookieService.setCookie('adstockUser', JSON.stringify(result), 1);
      this.messageService.add({
        severity: 'success', summary: 'Success', detail: `Remotely logged out. Welcome back ${result.name}`, life: 5000
      });
      window.location.href = '/users';
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to login remotely', detail: err.error.detail, life: 5000 });
    });
  }

}
