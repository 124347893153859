<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Purchase orders</h1>
    <button
      pButton pRipple
      class="p-button-outlined p-button-success"
      icon="pi pi-plus"
      label="Add purchase order"
      *ngIf="!loadingPurchaseOrders && currentUser.type === 'admin'"
      (click)="showAddDialog()"
    ></button>
  </div>
  <p-divider></p-divider>
  <p-card>
    <p-messages severity="warn" *ngIf="purchaseOrders.length === 0 && !loadingPurchaseOrders">
      <ng-template pTemplate>
        There are no purchase orders loaded.
      </ng-template>
    </p-messages>
    <div class="text-center" *ngIf="loadingPurchaseOrders">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dt
      *ngIf="!loadingPurchaseOrders && purchaseOrders.length > 0"
      [value]="purchaseOrders"
      [paginator]="true"
      [rowsPerPageOptions]="[5, 10, 25]"
      [rows]="5"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} stock takes"
      [globalFilterFields]="['purchase_order_number', 'date_ordered', 'supplier']"
      sortField="date_ordered"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="purchase_order_number">
            PO number
            <p-sortIcon field="purchase_order_number"></p-sortIcon>
          </th>
          <th pSortableColumn="date_ordered">
            Date ordered
            <p-sortIcon field="date_ordered"></p-sortIcon>
          </th>
          <th pSortableColumn="supplier">
            Supplier
            <p-sortIcon field="supplier"></p-sortIcon>
          </th>
          <th pSortableColumn="received">
            Received
            <p-sortIcon field="received"></p-sortIcon>
          </th>
          <th class="text-right">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-purchaseOrder>
        <tr>
          <td>#{{ purchaseOrder.purchase_order_number }}</td>
          <td>{{ purchaseOrder.date_ordered | date : 'dd/LL/yyyy' }}</td>
          <td>{{ purchaseOrder.supplier }}</td>
          <td>{{ purchaseOrder.received | date : 'dd/LL/yyyy' }}</td>
          <td class="text-right" *ngIf="currentUser.type === 'admin'">
            <a
              *ngIf="purchaseOrder.received; else receive"
              [routerLink]="['/purchase-orders', purchaseOrder.id, 'view']"
              pRipple pButton
              icon="pi pi-eye"
              class="p-button-rounded p-button-secondary p-button-sm p-mr-2"
              pTooltip="View purchase order"
              tooltipPosition="left"
            ></a>
            <ng-template #receive>
              <a
                [routerLink]="['/purchase-orders', purchaseOrder.id, 'receive']"
                pRipple pButton
                icon="pi pi-check"
                class="p-button-rounded p-button-info p-button-sm p-mr-2"
                pTooltip="Receive purchase order"
                tooltipPosition="left"
              ></a>
            </ng-template>
            <button
              type="button"
              pRipple pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              pTooltip="Delete purchase order"
              tooltipPosition="left"
              (click)="deletePurchaseOrder(purchaseOrder.id, $event)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search purchase orders">
          </span>
        </div>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmPopup></p-confirmPopup>

<p-blockUI [blocked]="checkIfLoggedIn">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
