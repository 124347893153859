<div class="adstock-remotely-logged-in p-shadow-2" *ngIf="generalService.isRemotelyLoggedIn()">
  Remotely logged in as {{ generalService.getRemoteName() }} |
  <a href="javascript:void(0);" (click)="generalService.remoteLogout()">
    <i class="pi pi-power-off" style="font-size: 0.8em;"></i>
    <span> Log out remotely</span>
  </a>
</div>
<router-outlet></router-outlet>
<p-toast></p-toast>


<p-blockUI [blocked]="generalService.isLoggingOutRemotely">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
