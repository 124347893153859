import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../../navigation/menu-item.model';
import { UserModel } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpService } from '../../services/http.service';
import { GeneralService } from '../../services/general.service';
import { StockTakeModel } from '../../models/stock-take.model';
import { DialogService } from 'primeng/dynamicdialog';
import { AddStockAreaDialogComponent } from './add-stock-area-dialog/add-stock-area-dialog.component';
import { StockAreaModel } from '../../models/stock-area.model';
import { CookieService } from '../../services/cookie.service';
import { StockProductModel } from '../../models/stock-product.model';
import { ExcelJson } from '../../models/excel-json';
import { formatDate } from '@angular/common';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-stock-take-details',
  templateUrl: './stock-take-details.component.html',
  styleUrls: ['./stock-take-details.component.css']
})
export class StockTakeDetailsComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  loadingStockTake = true;
  savingStockTake = false;

  isExporting = false;

  stockTakeId: number;

  stockTake: StockTakeModel;

  currentUser: UserModel;

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public httpService: HttpService,
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private cookieService: CookieService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          if (this.currentUser.type === 'admin') {
            this.route.params.subscribe(params => {
              this.stockTakeId = +params.id;
              this.httpService.getStockTake(this.stockTakeId).then(stockTake => {
                this.stockTake = stockTake;
                console.log(stockTake);
                this.loadingStockTake = false;
              }).catch(err => {
                this.messageService.add({ severity: 'error', summary: 'Unable to load stock take', detail: err.error.detail, life: 5000 });
              });
            });
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes', routerLinkActiveOptions: false },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  showAddAreaDialog(): void {
    const dialogRef = this.dialogService.open(AddStockAreaDialogComponent, {
      data: {
        companyId: this.currentUser.company_id,
        stockTakeId: this.stockTakeId
      },
      header: 'Add stock area',
      width: '50%'
    });
    dialogRef.onClose.subscribe((result: StockAreaModel) => {
      if (result) {
        this.stockTake.areas = [...this.stockTake.areas, result];
      }
    });
  }

  completeStockTake(): void {
    this.savingStockTake = true;
    const stockTake: StockTakeModel = {
      id: this.stockTakeId,
      name: this.stockTake.name,
      status: 'closed'
    };
    this.httpService.updateStockTake(stockTake).then(result => {
      this.stockTake = result;
      this.savingStockTake = false;
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to complete stock take', detail: err.error.detail, life: 5000 });
    });
  }

  reopenStockTake(): void {
    this.savingStockTake = true;
    const stockTake: StockTakeModel = {
      id: this.stockTakeId,
      name: this.stockTake.name,
      status: 'open'
    };
    this.httpService.updateStockTake(stockTake).then(result => {
      this.stockTake = result;
      this.savingStockTake = false;
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to re-open stock take', detail: err.error.detail, life: 5000 });
    });
  }

  exportScannedStock(): void {
    this.isExporting = true;
    this.httpService.getStockTakeProducts(this.stockTakeId).then(stockProducts => {
      const consolidatedProducts = this.consolidateProducts(stockProducts).then(products => {
        const data: ExcelJson = {
          data: [
            {
              A: this.stockTake.name + ' - ' + (formatDate(this.stockTake.stock_take_date, 'dd LLL yyyy', 'en-ZA'))
            },
            {},
            {
              A: 'Product',
              B: 'SKU',
              C: 'Barcode',
              D: 'Category',
              E: 'On hand'
            }
          ],
          skipHeader: true
        };
        for (const product of products) {
          data.data.push({
            A: product.name,
            B: product.stock_code,
            C: product.barcode,
            D: product.category,
            E: product.quantity,
          });
        }
        this.excelService.exportJsonToExcel(
          [data],
          'stock-take-' + this.stockTake.name.replace(' ', '-').toLowerCase() + '-' +
          formatDate(this.stockTake.stock_take_date, 'yyyy-LL-dd', 'en-ZA')
        );
        this.isExporting = false;
      });
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to fetch products', detail: err.error.detail, life: 5000 });
      this.isExporting = false;
    });
  }

  async consolidateProducts(stockProducts): Promise<StockProductModel[]> {
    const consolidatedProducts: StockProductModel[] = [];
    for (const product of stockProducts) {
      if (!(await this.productIdInConsolidated(product, consolidatedProducts))) {
        product.quantity = 1;
        consolidatedProducts.push(product);
      }
      else {
        await this.addConsolidatedProductQuantity(product, consolidatedProducts);
      }
    }
    return consolidatedProducts;
  }

  async productIdInConsolidated(product: StockProductModel, consolidatedProducts: StockProductModel[]): Promise<boolean> {
    for (const p of consolidatedProducts) {
      if (p.barcode === product.barcode && product.barcode !== '') {
        return true;
      }
      else if (product.barcode === '' && p.stock_code === product.stock_code && product.stock_code !== '') {
        return true;
      }
    }
    return false;
  }

  async addConsolidatedProductQuantity(product: StockProductModel, consolidatedProducts: StockProductModel[]): Promise<void> {
    for (const p of consolidatedProducts) {
      if (p.barcode === product.barcode && product.barcode !== '') {
        p.quantity += 1;
      }
      else if (product.barcode === '' && p.stock_code === product.stock_code && product.stock_code !== '') {
        p.quantity += 1;
      }
    }
  }

}
