<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-center">
    <h1>Receive purchase order</h1>
    <div>
      <button
        *ngIf="!loadingPurchaseOrderProducts && purchaseOrder && !purchaseOrder.received"
        type="button"
        pButton pRipple
        class="p-button-outlined p-button-success p-mr-2"
        [icon]="isCompleting ? 'pi pi-spinner pi-spin' : 'pi pi-check'"
        label="Complete"
        (click)="completelyReceivePurchaseOrder()"
      ></button>
      <a
        [routerLink]="['/purchase-orders']"
        pButton pRipple
        class="p-button-outlined p-button-secondary"
        icon="pi pi-arrow-left"
        label="Back"
      ></a>
    </div>
  </div>
  <h3 *ngIf="!loadingPurchaseOrderProducts && purchaseOrder">PO #{{ purchaseOrder.purchase_order_number }} - {{ purchaseOrder.date_ordered }} ({{ purchaseOrder.supplier }})</h3>
  <p-divider></p-divider>
  <p-card>
    <div class="p-grid" *ngIf="!loadingPurchaseOrderProducts && purchaseOrder && !purchaseOrder.received">
      <div class="p-col-12 p-md-4 p-fluid">
        <div class="p-float-label">
          <input type="text" pInputText id="barcode" #barcode (keyup.enter)="findAndReceiveProduct($event)" (keydown.tab)="findAndReceiveProduct($event)">
          <label for="barcode">Scan or enter barcode</label>
        </div>
      </div>
      <div class="p-col-12 p-md-4">
        <button
          type="button"
          pButton pRipple
          label="Find & add"
          [disabled]="isAdding"
          [icon]="isAdding ? 'pi pi-spinner pi-spin' : 'pi pi-search-plus'"
          class="p-button-outlined"
          (click)="findAndReceiveProduct()"
        ></button>
      </div>
    </div>
    <p-divider *ngIf="!loadingPurchaseOrderProducts && purchaseOrder && !purchaseOrder.received"></p-divider>
    <p-messages severity="warn" *ngIf="purchaseOrderProducts.length === 0 && !loadingPurchaseOrderProducts">
      <ng-template pTemplate>
        There are no products on this purchase order.
      </ng-template>
    </p-messages>
    <div class="text-center" *ngIf="loadingPurchaseOrderProducts">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dt
      *ngIf="!loadingPurchaseOrderProducts && purchaseOrderProducts.length > 0"
      [value]="purchaseOrderProducts"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [rows]="25"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} purchase order products"
      [globalFilterFields]="['name', 'stock_code', 'barcode']"
      sortField="name"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="barcode">
            Barcode
            <p-sortIcon field="barcode"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            Product
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="stock_code">
            SKU
            <p-sortIcon field="stock_code"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.barcode }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search products">
          </span>
        </div>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmPopup></p-confirmPopup>

<p-blockUI [blocked]="checkIfLoggedIn || isCompleting">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
