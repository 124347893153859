import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('email') email: ElementRef<HTMLInputElement>;
  @ViewChild('password') password: ElementRef<HTMLInputElement>;

  emailValid = true;
  emailNotEmpty = true;
  passwordNotEmpty = true;

  checkIfLoggedIn = true;
  isLoggingIn = false;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router, private generalService: GeneralService,
    private cookieService: CookieService
  ) { }

  async ngOnInit(): Promise<void> {
    if (await this.authService.isLoggedIn()) {
      await this.router.navigate(['/']);
    }
    this.checkIfLoggedIn = false;
  }

  validateEmail(): boolean {
    this.emailNotEmpty = this.email.nativeElement.value !== '';
    this.emailValid = this.emailNotEmpty ? this.generalService.validateEmail(this.email.nativeElement.value) : true;
    return this.emailValid && this.emailNotEmpty;
  }

  validatePassword(): boolean {
    this.passwordNotEmpty = this.password.nativeElement.value !== '';
    return this.passwordNotEmpty;
  }

  login(): void {
    this.isLoggingIn = true;

    let hasErrors = false;

    if (!this.validateEmail()) {
      hasErrors = true;
    }
    if (!this.validatePassword()) {
      hasErrors = true;
    }

    if (!hasErrors) {
      this.authService.login(this.email.nativeElement.value, this.password.nativeElement.value).then(result => {
        this.cookieService.setCookie('adstockUser', JSON.stringify(result), 1);
        this.isLoggingIn = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Welcome back ${result.name}`, life: 5000 });
        this.router.navigate(['/']).then();
      }).catch((error: HttpErrorResponse) => {
        this.messageService.add({ severity: 'error', summary: 'Login failed', detail: error.error.detail, life: 5000 });
        this.isLoggingIn = false;
      });
    }
    else {
      if (!this.emailNotEmpty || !this.emailValid) {
        this.email.nativeElement.focus();
      }
      else {
        this.password.nativeElement.focus();
      }
      this.isLoggingIn = false;
    }
  }

}
