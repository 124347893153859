<div class="p-fluid p-field p-mt-5">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-user"></i>
    </span>
    <div class="p-float-label">
      <input type="text" pInputText id="name" [class.p-invalid]="!nameNotEmpty" #name (keyup.enter)="updateUser()" (change)="validateName()" [(ngModel)]="user.name" autofocus>
      <label for="name">Name</label>
    </div>
  </div>
  <small class="p-invalid" *ngIf="!nameNotEmpty">Name is required</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-envelope"></i>
    </span>
    <div class="p-float-label">
      <input type="email" pInputText id="email" [class.p-invalid]="!emailNotEmpty || ! emailValid" #email (keyup.enter)="updateUser()" [(ngModel)]="user.email" (change)="validateEmail()">
      <label for="email">Email address</label>
    </div>
  </div>
  <small class="p-invalid" *ngIf="!emailNotEmpty">Email address is required</small>
  <small class="p-invalid" *ngIf="!emailValid">Email address is invalid (e.g. someone@example.com)</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-key"></i>
    </span>
    <div class="p-float-label">
      <input type="password" pPassword id="password" #password (keyup.enter)="updateUser()" >
      <label for="password">Password</label>
    </div>
  </div>
  <small>Enter a password if you want to change it</small>
</div>
<div class="p-fluid p-field p-mb-5 p-mt-5" *ngIf="currentUser.type === 'employee'">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-map-marker"></i>
    </span>
    <div class="p-float-label">
      <p-dropdown
        [options]="companies"
        [class.p-invalid]="!companySelected"
        [(ngModel)]="selectedCompany"
        [appendTo]="'body'"
        [autoDisplayFirst]="false"
        optionLabel="name"
        (onChange)="validateCompany()"
        optionValue="id"
        [disabled]="loadingCompanies || userIsEmployee"
        [showClear]="true"
        [virtualScroll]="true"
      ></p-dropdown>
      <label for="name">Company</label>
    </div>
  </div>
  <small class="p-invalid" *ngIf="!companySelected">Company is required</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-map-marker"></i>
    </span>
    <div class="p-float-label">
      <p-dropdown [options]="userTypes" (onChange)="checkUserTypeSelected()" [(ngModel)]="selectedUserType" [appendTo]="'body'" scrollHeight="120px" optionLabel="name" optionValue="value"></p-dropdown>
      <label for="name">User type</label>
    </div>
  </div>
</div>
<div class="text-right">
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving? 'pi pi-spinner pi-spin' : 'pi pi-times'"
    label="Cancel"
    class="p-button-outlined p-button-secondary"
    [disabled]="isSaving"
    (click)="dialogRef.close()"
  ></button>&nbsp;
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving ? 'pi pi-spinner pi-spin' : 'pi pi-save'"
    label="Save"
    [disabled]="isSaving"
    (click)="updateUser()"
  ></button>
</div>
