import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  setCookie(name: string, value: string, time: number = null): void {
    let expires = '';
    if (time) {
      const now = new Date();
      now.setTime(now.getTime() + (time * 24 * 60 * 60 * 1000));
      expires = '; expires=' + now.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookie(name): string {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (const i in ca) {
      if (ca.hasOwnProperty(i)) {
        const c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    return null;
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', -1);
  }

}
