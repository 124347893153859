<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Compare stock levels</h1>
    <a
      [routerLink]="['/stock-takes', stockTakeId]"
      pButton pRipple
      class="p-button-outlined p-button-secondary"
      icon="pi pi-arrow-left"
      label="Back"
    ></a>
  </div>
  <div class="p-d-flex p-jc-start p-ai-baseline">
    <h2 class="text-thin p-mr-5" *ngIf="!loadingStockTake">{{ stockTake.name }}</h2>
    <h3 class="text-thin" *ngIf="!loadingStockTake">{{ stockTake.stock_take_date | date : 'd LLL yyyy' }}</h3>
  </div>
  <p-divider></p-divider>
  <p-card>
    <p>Please choose your exported list <code>.csv</code> file. Here is an example of what the file should look like in order to import correctly.</p>
    <p-table [value]="exampleProducts">
      <ng-template pTemplate="header">
        <tr>
          <th>Barcode</th>
          <th>Stock level</th>
          <th>Product name</th>
          <th>SKU</th>
          <th>Category</th>
          <th>Action codes</th>
          <th>Markup</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-exampleProduct>
        <tr>
          <td>{{ exampleProduct.barcode }}</td>
          <td>{{ exampleProduct.stockLevel }}</td>
          <td>{{ exampleProduct.name }}</td>
          <td>{{ exampleProduct.stock_code }}</td>
          <td>{{ exampleProduct.category }}</td>
          <td>{{ exampleProduct.actionCodes }}</td>
          <td>{{ exampleProduct.markup }}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-messages severity="info">
      <ng-template pTemplate="">
        Please note that the first row will not be imported and will be treated as the column headings. The columns should be in this exact order for the import to succeed.
      </ng-template>
    </p-messages>
    <p-divider></p-divider>
    <p-fileUpload name="files[]" accept=".csv" [files]="comparisonFiles" [customUpload]="true" (uploadHandler)="compareStockTake($event.files)"></p-fileUpload>
  </p-card>
</div>


<p-blockUI [blocked]="checkIfLoggedIn || isComparing || loadingStockTake">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
