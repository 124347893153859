import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { HttpService } from '../../services/http.service';
import { ProductModel } from '../../models/product.model';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.css']
})
export class EditProductDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;
  @ViewChild('stockCode') stockCode: ElementRef<HTMLInputElement>;
  @ViewChild('barcode') barcode: ElementRef<HTMLInputElement>;
  @ViewChild('category') category: ElementRef<HTMLInputElement>;

  nameNotEmpty = true;
  stockCodeNotEmpty = true;
  categoryNotEmpty = true;

  isSaving = false;

  product: ProductModel;
  private backupProduct: ProductModel;

  constructor(
    public dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpService: HttpService,
    private dialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.product = this.dialogConfig.data.product;
    this.backupProduct = Object.assign({}, this.product);

    this.dialogRef.onClose.subscribe(result => {
      if (typeof result === 'undefined' || result !== true) {
        this.product.name = this.backupProduct.name;
        this.product.stock_code = this.backupProduct.stock_code;
        this.product.barcode = this.backupProduct.barcode;
        this.product.category = this.backupProduct.category;
      }
    });
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  validateStockCode(): boolean {
    this.stockCodeNotEmpty = this.stockCode.nativeElement.value !== '';
    return this.stockCodeNotEmpty;
  }

  validateCategory(): boolean {
    this.categoryNotEmpty = this.category.nativeElement.value !== '';
    return this.categoryNotEmpty;
  }

  updateProduct(): void {
    this.isSaving = true;

    let hasErrors = false;

    if (!this.validateName()) {
      hasErrors = true;
    }
    if (!this.validateStockCode()) {
      hasErrors = true;
    }
    if (!this.validateCategory()) {
      hasErrors = true;
    }

    if (!hasErrors) {
      const product: ProductModel = {
        id: this.product.id,
        company_id: this.product.company_id,
        name: this.name.nativeElement.value,
        stock_code: this.stockCode.nativeElement.value,
        barcode: this.barcode.nativeElement.value,
        category: this.category.nativeElement.value
      };
      this.httpService.updateProduct(product).then(result => {
        this.product.name = result.name;
        this.product.stock_code = result.stock_code;
        this.product.barcode = result.barcode;
        this.product.category = result.category;
        this.product.date_modified = result.date_modified;
        this.dialogRef.close(true);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t update product', detail: err.error.detail, life: 5000 });
        this.isSaving = false;
      });
    }
    else {
      this.isSaving = false;
    }
  }

}
