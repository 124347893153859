import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.css']
})
export class LockComponent implements OnInit {

  private authService;

  constructor(
    private httpService: HttpService,
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService = this.dialogConfig.data.auth;

    this.dialogRef.onClose.subscribe(result => {
      if (result) {
        this.cookieService.setCookie('adstockUserToken', this.authService.localToken, 1);
        this.authService.isLoggedIn().then();
        this.router.navigate(['/profile']).then(() => {
          this.router.navigate(['/']).then();
        });
      }
      else {
        this.authService.localToken = null;
        clearTimeout(this.authService.localTokenTimer);
        this.cookieService.deleteCookie('adstockUserToken');
        this.cookieService.deleteCookie('adstockUser');
        this.cookieService.deleteCookie('adstockUserAdmin');
        this.httpService.authAdmin = null;
        this.httpService.authToken = null;
        this.router.navigate(['/login']).then();
      }
    });
  }

}
