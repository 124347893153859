import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpService } from '../../services/http.service';
import { ExcelService } from '../../services/excel.service';
import { MessageService } from 'primeng/api';
import { PurchaseOrderModel } from '../../models/purchase-order.model';
import { UserModel } from '../../models/user.model';
import { PurchaseOrderProductModel } from '../../models/purchase-order-product.model';

@Component({
  selector: 'app-add-purchase-order-dialog',
  templateUrl: './add-purchase-order-dialog.component.html',
  styleUrls: ['./add-purchase-order-dialog.component.css']
})
export class AddPurchaseOrderDialogComponent implements OnInit {

  isUploading = false;

  currentUser: UserModel;

  constructor(
    public dialogRef: DynamicDialogRef,
    private httpService: HttpService,
    private excelService: ExcelService,
    private messageService: MessageService,
    private dialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.currentUser = this.dialogConfig.data.currentUser;
  }

  addPurchaseOrder(files: FileList): void {
    if (files && files.length > 0) {
      this.isUploading = true;

      const file: File = files[0];
      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const xlsx: any = reader.result;
        console.log(xlsx);
        this.excelService.readExcel(xlsx).then(data => {
          const dateOrderedArray = data[1][2].split('/');
          const dateOrdered = new Date(
            (dateOrderedArray[2].length === 4 ? dateOrderedArray[2] : '20' + dateOrderedArray[2]) +
            '-' + dateOrderedArray[1] +
            '-' + dateOrderedArray[0]
          );
          const purchaseOrderNumber = data[2][2];
          const supplier = data[5][2];
          this.httpService.createPurchaseOrder(
            dateOrdered, purchaseOrderNumber, supplier, this.currentUser.company_id
          ).then(purchaseOrder => {
            const products: any[] = [];
            for (const i in data) {
              if (data.hasOwnProperty(i) && parseInt(i, 10) > 12 && typeof data[i][2] !== 'undefined') {
                const product: PurchaseOrderProductModel = {
                  quantity_ordered: parseInt(data[i][2], 10),
                  stock_code: data[i][5],
                  name: data[i][6],
                  price: parseFloat(data[i][13]),
                  pack_quantity: parseInt(data[i][4], 10),
                  purchase_order_id: purchaseOrder.id
                };
                products.push(product);
              }
            }
            this.httpService.bulkCreatePurchaseOrderProducts(products, purchaseOrder.id).then(result => {
              this.dialogRef.close(purchaseOrder);
            }).catch(err => {
              this.messageService.add({
                severity: 'error', summary: 'Unable to create purchase order products', detail: err.error.detail, life: 5000
              });
              this.isUploading = false;
            });
          }).catch(err => {
            this.messageService.add({
              severity: 'error', summary: 'Unable to create purchase order', detail: err.error.detail, life: 5000
            });
            this.isUploading = false;
          });
        });
      };
    }
  }

}
