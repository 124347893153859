<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Stock takes</h1>
    <button
      pButton pRipple
      class="p-button-outlined p-button-success"
      icon="pi pi-plus"
      label="Add stock take"
      *ngIf="!loadingStockTakes && currentUser.type === 'admin'"
      (click)="showAddDialog()"
    ></button>
  </div>
  <p-divider></p-divider>
  <p-card>
    <p-messages severity="warn" *ngIf="stockTakes.length === 0 && !loadingStockTakes">
      <ng-template pTemplate>
        There are no stock takes loaded.
      </ng-template>
    </p-messages>
    <div class="text-center" *ngIf="loadingStockTakes">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dt
      *ngIf="!loadingStockTakes && stockTakes.length > 0"
      [value]="stockTakes"
      [paginator]="true"
      [rowsPerPageOptions]="[5, 10, 25]"
      [rows]="5"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} stock takes"
      [globalFilterFields]="['name']"
      sortField="name"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id" style="width: 100px;" *ngIf="currentUser.type === 'employee'">
            #
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="stock_take_date">
            Date
            <p-sortIcon field="stock_take_date"></p-sortIcon>
          </th>
          <th pSortableColumn="status">
            Status
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th class="text-right">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stockTake>
        <tr>
          <td *ngIf="currentUser.type === 'employee'">{{ stockTake.id }}</td>
          <td>{{ stockTake.name }}</td>
          <td>{{ stockTake.stock_take_date | date : 'dd/MM/yyyy' }}</td>
          <td>{{ stockTake.status | titlecase }}</td>
          <td class="text-right" *ngIf="currentUser.type === 'admin'">
            <button
              type="button"
              pRipple pButton
              icon="pi pi-check"
              class="p-button-rounded p-button-success p-button-sm p-mr-2"
              pTooltip="Complete stock take"
              tooltipPosition="left"
              *ngIf="stockTake.status === 'open' && stockTake.areas.length"
              (click)="completeStockTake(stockTake, $event)"
            ></button>
            <button
              type="button"
              pRipple pButton
              icon="pi pi-replay"
              class="p-button-rounded p-button-info p-button-sm p-mr-2"
              pTooltip="Re-open stock take"
              tooltipPosition="left"
              *ngIf="stockTake.status === 'closed' && currentUser.type === 'admin'"
              (click)="reopenStockTake(stockTake, $event)"
            ></button>
            <a
              [routerLink]="['/stock-takes', stockTake.id]"
              pRipple pButton
              icon="pi pi-eye"
              class="p-button-rounded p-button-secondary p-button-sm p-mr-2"
              pTooltip="View stock take"
              tooltipPosition="left"
            ></a>
            <button
              type="button"
              pRipple pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              pTooltip="Delete stock take"
              tooltipPosition="left"
              (click)="deleteStockTake(stockTake.id, $event)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search stock takes">
          </span>
        </div>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmPopup></p-confirmPopup>

<p-blockUI [blocked]="checkIfLoggedIn">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
