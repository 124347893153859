<div class="adstock-side-menu p-shadow-2">
  <div class="header">
    Ad<strong>Stock</strong>
  </div>
  <ul class="adstock-menu-list">
    <li *ngFor="let item of menuItems">
      <a *ngIf="item.hasOwnProperty('click')" pRipple (click)="item.click()" href="javascript:void(0);"><i *ngIf="item.icon" [class]="item.icon"></i>{{ item.label }}</a>
      <a *ngIf="item.routerLink" pRipple [routerLink]="item.routerLink" routerLinkActive="active" [routerLinkActiveOptions]="item.routerLinkActiveOptions !== false ? { exact: true } : { exact: false }"><i *ngIf="item.icon" [class]="item.icon"></i>{{ item.label }}</a>
    </li>
    <li>
      <a href="javascript:void(0);" pRipple (click)="authService.logout()" class="logout"><i class="pi pi-power-off"></i>Logout</a>
    </li>
  </ul>
</div>
