import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-stock-area-dialog',
  templateUrl: './add-stock-area-dialog.component.html',
  styleUrls: ['./add-stock-area-dialog.component.css']
})
export class AddStockAreaDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;

  nameNotEmpty = true;

  companyId: number;
  stockTakeId: number;

  isSaving = false;

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.companyId = this.dialogConfig.data.companyId;
    this.stockTakeId = this.dialogConfig.data.stockTakeId;
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  addStockArea(): void {
    this.isSaving = true;

    if (this.validateName()) {
      this.httpService.createStockArea(this.name.nativeElement.value, this.companyId, this.stockTakeId).then(result => {
        this.dialogRef.close(result);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t add stock area', detail: err.error.message, life: 5000 });
      });
    }
    else {
      this.isSaving = false;
    }
  }

}
