<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <input type="text" pInputText id="name" [class.p-invalid]="!nameNotEmpty" #name autofocus (keyup.enter)="addStockTake()" (change)="validateName()">
    <label for="name">Stock take name</label>
  </div>
  <small class="p-invalid" *ngIf="!nameNotEmpty">Product name is required</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <p-calendar [(ngModel)]="stockTakeDate" [inputId]="'stockTakeDate'" [class.p-invalid]="!stockTakeDate" dateFormat="yy-mm-dd" [showIcon]="true" appendTo="body"></p-calendar>
    <label for="stockTakeDate">Date of stock take</label>
  </div>
  <small class="p-invalid" *ngIf="!stockTakeDate">Date is required</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <p-multiSelect [options]="categories" [inputId]="'categories'" appendTo="body" optionValue="code" optionLabel="name" [(ngModel)]="selectedCategories"></p-multiSelect>
    <label for="categories">Categories</label>
  </div>
  <small class="p-invalid" *ngIf="!stockTakeDate">Date is required</small>
</div>
<div class="text-right">
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving? 'pi pi-spinner pi-spin' : 'pi pi-times'"
    label="Cancel"
    class="p-button-outlined p-button-secondary"
    [disabled]="isSaving"
    (click)="dialogRef.close()"
  ></button>&nbsp;
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving ? 'pi pi-spinner pi-spin' : 'pi pi-save'"
    label="Save"
    [disabled]="isSaving"
    (click)="addStockTake()"
  ></button>
</div>


<p-blockUI [blocked]="loadingCategories">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
