import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { HttpService } from './http.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserModel } from '../models/user.model';
import { DialogService } from 'primeng/dynamicdialog';
import { LockComponent } from '../auth/lock/lock.component';
import { CookieService } from './cookie.service';

export interface AccessToken {
  access_token: string;
  bearer: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  localTokenTimer;
  localToken: string;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private router: Router,
    private dialogService: DialogService,
    private cookieService: CookieService
  ) { }

  startAuthenticationTimer(): void {
    clearTimeout(this.localTokenTimer);
    this.localToken = this.cookieService.getCookie('adstockUserToken');
    this.localTokenTimer = setTimeout(() => {
      this.dialogService.open(LockComponent, {
        data: { auth: this },
        header: 'Are you still there?',
        width: '50%'
      });
    }, 1000 * 60 * 30);
  }

  login(email: string, password: string): Promise<void | any> {
    const body = new HttpParams().set('username', email).set('password', password);
    return this.http.post(this.httpService.url + 'auth/token', body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    }).toPromise().then((result: AccessToken) => {
      this.cookieService.setCookie('adstockUserToken', result.access_token, 1);
      this.httpService.authToken = result.access_token;
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + result.access_token);
      return this.http.get(this.httpService.url + 'auth/current-user', { headers }).toPromise();
    }).catch((error: HttpErrorResponse) => {
      return throwError(error).toPromise().then(err => err);
    });
  }

  async isLoggedIn(): Promise<boolean> {
    if (this.cookieService.getCookie('adstockUserToken') !== null) {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.cookieService.getCookie('adstockUserToken'));
      return this.http.get(this.httpService.url + 'auth/current-user', { headers }).toPromise().then(result => {
        if (this.cookieService.getCookie('adstockUser') === null) {
          this.cookieService.setCookie('adstockUser', JSON.stringify(result), 1);
        }
        // this.startAuthenticationTimer();
        return true;
      }).catch(() => {
        this.cookieService.deleteCookie('adstockUserToken');
        this.cookieService.deleteCookie('adstockUser');
        this.cookieService.deleteCookie('adstockUserAdmin');
        clearTimeout(this.localTokenTimer);
        this.localToken = null;
        this.httpService.authToken = null;
        this.httpService.authAdmin = null;
        return false;
      });
    }
    else {
      return false;
    }
  }

  logout(): void {
    clearTimeout(this.localTokenTimer);
    this.httpService.authAdmin = null;
    this.httpService.authToken = null;
    this.cookieService.deleteCookie('adstockUserToken');
    this.cookieService.deleteCookie('adstockUser');
    this.cookieService.deleteCookie('adstockUserAdmin');
    this.router.navigate(['/login']).then();
  }

  async remoteLogin(id: number): Promise<void | any> {
    const user = await this.httpService.getUser(id);
    const currentUser: UserModel = JSON.parse(this.cookieService.getCookie('adstockUser'));
    const body = { email: user.email, admin_email: currentUser.email };
    return this.http.post(this.httpService.url + 'auth/token/remote', body, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.httpService.authToken}`)
    }).toPromise().then((result: AccessToken) => {
      this.cookieService.setCookie('adstockUserToken', result.access_token, 1);
      this.cookieService.setCookie('adstockUserAdmin', currentUser.email, 1);
      this.httpService.authAdmin = currentUser.email;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${result.access_token}`);
      return this.http.get(this.httpService.url + 'auth/current-user', { headers }).toPromise();
    }).catch((error: HttpErrorResponse) => {
      return throwError(error).toPromise().then(err => err);
    });
  }

  remoteLogout(): Promise<void | any> {
    return this.http.post(this.httpService.url + 'auth/remote/logout', {}, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.httpService.authToken}`)
    }).toPromise().then((result: AccessToken) => {
      this.cookieService.setCookie('adstockUserToken', result.access_token, 1);
      this.cookieService.deleteCookie('adstockUserAdmin');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + result.access_token);
      return this.http.get(this.httpService.url + 'auth/current-user', { headers }).toPromise();
    }).catch((error: HttpErrorResponse) => {
      return throwError(error).toPromise().then(err => err);
    });
  }

}
