import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
import { ExcelJson } from '../models/excel-json';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  exportJsonToExcel(json: ExcelJson[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      json[0].data,
      this.getOptions(json[0])
    );

    for (let i = 1, length = json.length; i < length; i++) {
      XLSX.utils.sheet_add_json(
        worksheet,
        [{}],
        this.getOptions({
          data: [],
          skipHeader: true
        }, -1)
      );
      XLSX.utils.sheet_add_json(
        worksheet,
        json[i].data,
        this.getOptions(json[i], -1)
      );
    }
    const workbook: XLSX.WorkBook = { Sheets: { Sheet1: worksheet }, SheetNames: ['Sheet1'] };
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  getOptions(json: ExcelJson, origin?: number): any {
    const options = {
      skipHeader: true,
      origin: -1,
      header: []
    };
    options.skipHeader = json.skipHeader ? json.skipHeader : false;
    if (!options.skipHeader && json.header && json.header.length) {
      options.header = json.header;
    }
    if (origin) {
      options.origin = origin ? origin : -1;
    }
    return options;
  }

  readExcel(buffer): Promise<any> {
    const workbook = new Excel.Workbook();
    const data: any[] = [];
    return workbook.xlsx.load(buffer).then(result => {
      const sheet = result.getWorksheet(1);
      sheet.eachRow({ includeEmpty: false }, (row, rowNum) => {
        data[rowNum] = [];
        row.eachCell({ includeEmpty: false }, (cell, colNum) => {
          data[rowNum][colNum] = cell.value;
        });
      });
      return data;
    });
  }

}
