import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../navigation/menu-item.model';
import { UserModel } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../services/http.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingUsers = true;

  users: UserModel[] = [];

  isLoggingInRemotely = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public httpService: HttpService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/companies'], icon: 'pi pi-map-marker', label: 'Companies' },
            { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
          ];

          this.httpService.getUsersAsEmployee().then(users => {
            this.users = users;
            this.getUserCompanyNames();
            this.loadingUsers = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch users', detail: err.error.detail, life: 5000 });
          });
        }
        else if (this.currentUser.type === 'admin') {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
            { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
            { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
            { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
          ];

          this.httpService.getUsersAsAdmin(this.currentUser.company_id).then(users => {
            this.users = users;
            this.getUserCompanyNames();
            this.loadingUsers = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch users', detail: err.error.detail, life: 5000 });
          });
        }
        else {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  showAddDialog(): void {
    const dialogRef = this.dialogService.open(AddUserDialogComponent, {
      header: 'Add user',
      width: '50%'
    });
    dialogRef.onClose.subscribe((user: UserModel) => {
      if (user) {
        if (user.type === 'employee') {
          user.company_name = '-';
        }
        else {
          this.httpService.getCompanyName(user.company_id).then(name => user.company_name = name).catch(() => user.company_name = '-');
        }
        this.users = [...this.users, user];
      }
    });
  }

  showEditDialog(user: UserModel): void {
    const dialogRef = this.dialogService.open(EditUserDialogComponent, {
      data: {
        user
      },
      header: 'Edit user',
      width: '50%'
    });
  }

  deleteUser(id: number, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this user?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingUsers = true;
        this.httpService.deleteUser(id).then(() => {
          this.httpService.getUsersAsEmployee().then(users => {
            this.users = users;
            this.getUserCompanyNames();
            this.loadingUsers = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch users', detail: err.error.detail, life: 5000 });
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Couldn\'t delete user', detail: err.error.detail, life: 5000 });
        });
      }
    });
  }

  remoteLogin(id: number): void {
    this.isLoggingInRemotely = true;
    this.authService.remoteLogin(id).then(result => {
      this.isLoggingInRemotely = false;
      this.cookieService.setCookie('adstockUser', JSON.stringify(result), 1);
      this.httpService.authToken = this.cookieService.getCookie('adstockUserToken');
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Remotely logged in as ${result.name}`, life: 5000 });
      this.router.navigate(['/']).then();
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to login remotely', detail: err.error.detail, life: 5000 });
    });
  }

  private getUserCompanyNames(): void {
    this.users.forEach(user => {
      if (user.company_id) {
        this.httpService.getCompanyName(user.company_id).then(name => user.company_name = name).catch(() => user.company_name = '-');
      }
      else {
        user.company_name = '-';
      }
    });
  }

}
