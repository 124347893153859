import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../../../navigation/menu-item.model';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from '../../../models/user.model';
import { CookieService } from '../../../services/cookie.service';
import { MessageService } from 'primeng/api';
import { HttpService } from '../../../services/http.service';
import { StockTakeModel } from '../../../models/stock-take.model';
import { StockProductModel } from '../../../models/stock-product.model';
import { ExcelJson } from '../../../models/excel-json';
import { formatDate } from '@angular/common';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-compare-stock-take',
  templateUrl: './compare-stock-take.component.html',
  styleUrls: ['./compare-stock-take.component.css']
})
export class CompareStockTakeComponent implements OnInit {

  menuItems: MenuItemModel[] = [];

  checkIfLoggedIn = true;

  loadingStockTake = true;
  isComparing = false;

  currentUser: UserModel;

  exampleProducts: any[] = [
    {
      name: 'Example 1', stock_code: 'EXA001', barcode: '654654654654', category: 'Petshop', stockLevel: '5', actionCodes: 'S', markup: '33'
    },
    {
      name: 'Example 2', stock_code: 'EXA002', barcode: '654321321321', category: 'Acana', stockLevel: '2', actionCodes: 'SF', markup: '60'
    },
    {
      name: '...', stock_code: '...', barcode: '...', category: '...', stockLevel: '...', actionCodes: '...', markup: '...'
    },
  ];

  stockTakeId: number;

  stockTake: StockTakeModel;

  stockProducts: StockProductModel[] = [];
  consolidatedProducts: StockProductModel[] = [];

  comparisonFiles = [];

  private matches: any[] = [];
  private tooLow: any[] = [];
  private tooHigh: any[] = [];
  private notScanned: any[] = [];
  private notFound: any[];

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          if (this.currentUser.type === 'admin') {
            this.route.params.subscribe(params => {
              this.stockTakeId = +params.id;
              this.httpService.getStockTake(this.stockTakeId).then(stockTake => {
                this.stockTake = stockTake;
                this.httpService.getStockTakeProducts(this.stockTakeId).then(stockProducts => {
                  this.stockProducts = stockProducts;
                  this.consolidateProducts().then(() => {
                    this.loadingStockTake = false;
                  });
                }).catch(err => {
                  this.messageService.add({
                    severity: 'error', summary: 'Unable to load stock take', detail: err.error.detail, life: 5000
                  });
                });
              }).catch(err => {
                this.messageService.add({ severity: 'error', summary: 'Unable to load stock take', detail: err.error.detail, life: 5000 });
              });
            });
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes', routerLinkActiveOptions: false },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  compareStockTake(files: FileList): void {
    if (files && files.length > 0) {
      this.isComparing = true;

      const file: File = files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = async (e) => {
        const csv: any = reader.result;
        const allLines = csv.split(/\n/);
        const lineLength = allLines.length;
        let rowLengthIssue = false;
        let rowFieldIssue = false;
        for (let i = 0; i < lineLength; i++) {
          if (allLines[i] !== '' && i > 0) {
            const row = allLines[i].replace(/^"/, '').replace(/"(\r|\n)?$/, '').split(/","/);
            if (row.length < 7 || row.length > 7) {
              console.log(row);
              rowLengthIssue = true;
              continue;
            }
            if ((row[0] === '' && row[3] === '') || row[1] === '') {
              console.log(row);
              rowFieldIssue = true;
              continue;
            }
            const productCheck = {
              barcode: row[0],
              stockLevel: row[1],
              name: row[2],
              stock_code: row[3],
              category: row[4],
              actionCodes: row[5],
              markup: row[6],
            };
            this.findByCodes(productCheck);
          }
        }
        if (rowLengthIssue) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Some of the products in the file did not load properly. Please ask an administrator to have a look at the logs.',
            life: 10000
          });
        }
        if (rowFieldIssue) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Some of the products in the file does not have all required fields. ' +
              'Please ask an administrator to have a look at the logs.',
            life: 10000
          });
        }

        this.notFound = [...this.consolidatedProducts];

        this.exportComparisonToExcel().then(() => {
          this.isComparing = false;
          this.comparisonFiles = [];
        });
      };
    }
  }

  findByCodes(row: any): void {
    let found = false;
    for (const product of this.consolidatedProducts) {
      if ((product.barcode !== '' && row.barcode !== '' && product.barcode === row.barcode) ||
        (product.stock_code !== '' && row.stock_code !== '' && product.stock_code === row.stock_code)) {
        this.addRowToLists(row, product);
        found = true;
      }
    }
    if (!found) {
      if (parseInt(row.stockLevel, 10) === 0) {
        row.quantity = 0;
        this.matches.push(row);
      }
      else {
        this.notScanned.push(row);
      }
    }
  }

  addRowToLists(row: any, product: StockProductModel): void {
    row.quantity = product.quantity;
    if (product.quantity === parseInt(row.stockLevel, 10)) {
      this.matches.push(row);
    }
    else if (product.quantity < parseInt(row.stockLevel, 10)) {
      this.tooLow.push(row);
    }
    else if (product.quantity > parseInt(row.stockLevel, 10)) {
      this.tooHigh.push(row);
    }
    this.consolidatedProducts.splice(this.consolidatedProducts.indexOf(product), 1);
  }

  async consolidateProducts(): Promise<void> {
    for (const product of this.stockProducts) {
      if (!(await this.productIdInConsolidated(product))) {
        product.quantity = 1;
        this.consolidatedProducts.push(product);
      }
      else {
        await this.addConsolidatedProductQuantity(product);
      }
    }
  }

  async productIdInConsolidated(product: StockProductModel): Promise<boolean> {
    for (const p of this.consolidatedProducts) {
      if (p.barcode === product.barcode && product.barcode !== '') {
        return true;
      }
      else if (product.barcode === '' && p.stock_code === product.stock_code && product.stock_code !== '') {
        return true;
      }
    }
    return false;
  }

  async addConsolidatedProductQuantity(product: StockProductModel): Promise<void> {
    for (const p of this.consolidatedProducts) {
      if (p.barcode === product.barcode && product.barcode !== '') {
        p.quantity += 1;
      }
      else if (product.barcode === '' && p.stock_code === product.stock_code && product.stock_code !== '') {
        p.quantity += 1;
      }
    }
  }

  async exportComparisonToExcel(): Promise<void> {
    const data: ExcelJson = {
      data: [
        {
          A: this.stockTake.name + ' - ' + (formatDate(this.stockTake.stock_take_date, 'dd LLL yyyy', 'en-ZA'))
        },
        {},
        {
          A: 'MATCHES'
        },
        {
          A: 'Product',
          B: 'SKU',
          C: 'Barcode',
          D: 'On hand',
          E: 'Stock level',
          F: 'Action codes',
          G: 'Markup'
        }
      ],
      skipHeader: true
    };
    for (const product of this.matches) {
      data.data.push({
        A: product.name,
        B: product.stock_code,
        C: product.barcode,
        D: product.quantity,
        E: product.stockLevel,
        F: product.actionCodes,
        G: product.markup
      });
    }

    data.data.push({});
    data.data.push({
      A: 'TOO LOW'
    });
    data.data.push({
      A: 'Product',
      B: 'SKU',
      C: 'Barcode',
      D: 'On hand',
      E: 'Stock level',
      F: 'Action codes',
      G: 'Markup'
    });
    for (const product of this.tooLow) {
      data.data.push({
        A: product.name,
        B: product.stock_code,
        C: product.barcode,
        D: product.quantity,
        E: product.stockLevel,
        F: product.actionCodes,
        G: product.markup
      });
    }

    data.data.push({});
    data.data.push({
      A: 'TOO HIGH'
    });
    data.data.push({
      A: 'Product',
      B: 'SKU',
      C: 'Barcode',
      D: 'On hand',
      E: 'Stock level',
      F: 'Action codes',
      G: 'Markup'
    });
    for (const product of this.tooHigh) {
      data.data.push({
        A: product.name,
        B: product.stock_code,
        C: product.barcode,
        D: product.quantity,
        E: product.stockLevel,
        F: product.actionCodes,
        G: product.markup
      });
    }

    data.data.push({});
    data.data.push({
      A: 'NOT ON SYSTEM'
    });
    data.data.push({
      A: 'Product',
      B: 'SKU',
      C: 'Barcode',
      D: 'On hand',
      E: '',
      F: '',
      G: ''
    });
    for (const product of this.notFound) {
      data.data.push({
        A: product.name,
        B: product.stock_code,
        C: product.barcode,
        D: product.quantity
      });
    }

    data.data.push({});
    data.data.push({
      A: 'NOT SCANNED'
    });
    data.data.push({
      A: 'Product',
      B: 'SKU',
      C: 'Barcode',
      D: 'On hand',
      E: 'Stock level',
      F: 'Action codes',
      G: 'Markup'
    });
    for (const product of this.notScanned) {
      data.data.push({
        A: product.name,
        B: product.stock_code,
        C: product.barcode,
        D: 0,
        E: product.stockLevel,
        F: product.actionCodes,
        G: product.markup
      });
    }

    this.excelService.exportJsonToExcel(
      [data],
      'stock-take-comparison-' + this.stockTake.name.replace(' ', '-').toLowerCase() + '-' +
      formatDate(this.stockTake.stock_take_date, 'yyyy-LL-dd', 'en-ZA')
    );
  }

}
