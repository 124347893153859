import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItemModel } from '../../navigation/menu-item.model';
import { UserModel } from '../../models/user.model';
import { PurchaseOrderModel } from '../../models/purchase-order.model';
import { PurchaseOrderProductModel } from '../../models/purchase-order-product.model';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../../services/http.service';
import { CookieService } from '../../services/cookie.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-receive-purchase-order',
  templateUrl: './receive-purchase-order.component.html',
  styleUrls: ['./receive-purchase-order.component.css']
})
export class ReceivePurchaseOrderComponent implements OnInit {

  @ViewChild('barcode') barcode: ElementRef<HTMLInputElement>;

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingPurchaseOrderProducts = true;

  isAdding = false;
  isCompleting = false;

  purchaseOrderId: number;
  purchaseOrder: PurchaseOrderModel;

  purchaseOrderProducts: PurchaseOrderProductModel[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          this.route.params.subscribe(params => {
            this.purchaseOrderId = params.id;

            this.httpService.getPurchaseOrder(this.purchaseOrderId).then(purchaseOrder => {
              this.purchaseOrder = purchaseOrder;
              this.httpService.getPurchaseOrderProducts(this.purchaseOrderId).then(purchaseOrderProducts => {
                this.purchaseOrderProducts = purchaseOrderProducts;
                this.loadingPurchaseOrderProducts = false;
                this.barcode.nativeElement.focus();
              }).catch(err => {
                this.messageService.add({
                  severity: 'error', summary: 'Couldn\'t fetch purchase order products', detail: err.error.detail, life: 5000
                });
              });
            }).catch(err => {
              this.messageService.add({
                severity: 'error', summary: 'Couldn\'t fetch purchase order', detail: err.error.detail, life: 5000
              });
            });
          });

          if (this.currentUser.type === 'admin') {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders', routerLinkActiveOptions: false },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  findAndReceiveProduct(event: Event = null): void {
    if (typeof event !== 'undefined' && event !== null) {
      event.preventDefault();
    }
    this.isAdding = true;
    if (this.barcode.nativeElement.value === '') {
      this.isAdding = false;
      this.barcode.nativeElement.focus();
      return;
    }

    this.httpService.addPurchaseOrderProductReceiveWithBarcode(this.barcode.nativeElement.value, this.purchaseOrderId).then(() => {
      this.isAdding = false;
      this.barcode.nativeElement.value = '';
      this.barcode.nativeElement.focus();
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to receive product', detail: err.error.detail, life: 5000 });
      this.barcode.nativeElement.focus();
      this.isAdding = false;
    });
  }

  completelyReceivePurchaseOrder(): void {
    this.isCompleting = true;

    const purchaseOrder: PurchaseOrderModel = {
      id: this.purchaseOrderId,
      date_ordered: this.purchaseOrder.date_ordered,
      purchase_order_number: this.purchaseOrder.purchase_order_number,
      received: formatDate(new Date(), 'yyyy-LL-dd', 'en-ZA'),
      user_id: this.currentUser.id
    };

    this.httpService.updatePurchaseOrder(purchaseOrder).then(result => {
      this.purchaseOrder = result;
      this.isCompleting = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Purchase order successfully completed.', life: 5000 });
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to complete purchase order', detail: err.error.detail, life: 5000 });
      this.isCompleting = false;
    });
  }

}
