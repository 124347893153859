import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../../navigation/menu-item.model';
import { UserModel } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../../services/http.service';
import { GeneralService } from '../../services/general.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ProductModel } from '../../models/product.model';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-import-products',
  templateUrl: './import-products.component.html',
  styleUrls: ['./import-products.component.css']
})
export class ImportProductsComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  isUploading = false;

  currentUser: UserModel;

  exampleProducts: ProductModel[] = [
    { name: 'Example 1', stock_code: 'EXA001', barcode: '654654654654', category: 'Petshop' },
    { name: 'Example 2', stock_code: 'EXA002', barcode: '654321321321', category: 'Acana' },
    { name: '...', stock_code: '...', barcode: '...', category: '...' },
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public httpService: HttpService,
    public generalService: GeneralService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else if (this.currentUser.type === 'admin') {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products', routerLinkActiveOptions: false },
            { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
            { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
            { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
          ];
        }
        else {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  uploadProducts(files: FileList): void {
    if (files && files.length > 0) {
      this.isUploading = true;

      const file: File = files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      const products: ProductModel[] = [];
      reader.onload = async (e) => {
        const csv: any = reader.result;
        const allLines = csv.split(/\n/);
        const lineLength = allLines.length;
        for (let i = 0; i < lineLength; i++) {
          if (allLines[i] !== '' && i > 0) {
            const row = allLines[i].replace(/^"/, '').replace(/"(\r|\n)?$/, '').split(/","/);
            if (row.length < 6 || row.length > 6) {
              console.log(row);
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Some of the products in the file did not load properly. Please ask an administrator to have a look at the logs.',
                life: 10000
              });
              continue;
            }
            if (row[0] === '' || row[1] === '' || row[3] === '') {
              console.log(row);
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Some of the products in the file does not have all required fields. ' +
                  'Please ask an administrator to have a look at the logs.',
                life: 10000
              });
              continue;
            }
            const product: ProductModel = {
              name: row[0],
              stock_code: row[1],
              barcode: row[2],
              category: row[3],
              company_id: this.currentUser.company_id
            };
            products.push(product);
          }
        }
        this.httpService.bulkCreateProducts(products).then(result => {
          if (result) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Products successfully uploaded.', life: 3000 });
            this.router.navigate(['/products']).then();
          }
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Failed to upload products', detail: err.error.detail, life: 5000 });
          this.isUploading = false;
        });
      };
    }
  }

}
