import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../navigation/menu-item.model';
import { UserModel } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../services/http.service';
import { GeneralService } from '../services/general.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CookieService } from '../services/cookie.service';
import { PurchaseOrderModel } from '../models/purchase-order.model';
import { AddPurchaseOrderDialogComponent } from './add-purchase-order-dialog/add-purchase-order-dialog.component';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})
export class PurchaseOrdersComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingPurchaseOrders = true;

  purchaseOrders: PurchaseOrderModel[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public httpService: HttpService,
    public generalService: GeneralService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          this.httpService.getPurchaseOrders(this.currentUser.company_id).then(purchaseOrders => {
            this.purchaseOrders = purchaseOrders;
            this.loadingPurchaseOrders = false;
          }).catch(err => {
            this.messageService.add({
              severity: 'error', summary: 'Couldn\'t fetch purchase orders', detail: err.error.detail, life: 5000
            });
          });

          if (this.currentUser.type === 'admin') {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  showAddDialog(): void {
    const dialogRef = this.dialogService.open(AddPurchaseOrderDialogComponent, {
      data: {
        currentUser: this.currentUser
      },
      header: 'Add purchase order',
      width: '50%'
    });
    dialogRef.onClose.subscribe((purchaseOrder: PurchaseOrderModel) => {
      this.purchaseOrders = [...this.purchaseOrders, purchaseOrder];
    });
  }

  deletePurchaseOrder(id: number, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this purchase order?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingPurchaseOrders = true;
        this.httpService.deletePurchaseOrder(id).then(() => {
          this.httpService.getPurchaseOrders(this.currentUser.company_id).then(purchaseOrders => {
            this.purchaseOrders = purchaseOrders;
            this.loadingPurchaseOrders = false;
          }).catch(err => {
            this.messageService.add({
              severity: 'error', summary: 'Unable to fetch purchase orders', detail: err.error.detail, life: 5000
            });
            this.loadingPurchaseOrders = false;
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Couldn\'t delete purchase order', detail: err.error.detail, life: 5000 });
          this.loadingPurchaseOrders = false;
        });
      }
    });
  }

}
