<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <input type="text" pInputText id="name" [class.p-invalid]="!nameNotEmpty" #name (keyup.enter)="addProduct()" (change)="validateName()" autofocus>
    <label for="name">Product name</label>
  </div>
  <small class="p-invalid" *ngIf="!nameNotEmpty">Product name is required</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <input type="text" pInputText id="stockCode" [class.p-invalid]="!stockCodeNotEmpty" #stockCode (keyup.enter)="addProduct()" (change)="validateStockCode()">
    <label for="stockCode">SKU</label>
  </div>
  <small class="p-invalid" *ngIf="!stockCodeNotEmpty">SKU is required</small>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <input type="text" pInputText id="barcode" #barcode (keyup.enter)="addProduct()">
    <label for="barcode">Barcode</label>
  </div>
</div>
<div class="p-fluid p-field p-mt-5">
  <div class="p-float-label">
    <input type="text" pInputText id="category" [class.p-invalid]="!categoryNotEmpty" #category (keyup.enter)="addProduct()" (change)="validateCategory()">
    <label for="category">Category</label>
  </div>
  <small class="p-invalid" *ngIf="!categoryNotEmpty">Category is required</small>
</div>
<div class="text-right">
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving? 'pi pi-spinner pi-spin' : 'pi pi-times'"
    label="Cancel"
    class="p-button-outlined p-button-secondary"
    [disabled]="isSaving"
    (click)="dialogRef.close()"
  ></button>&nbsp;
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving ? 'pi pi-spinner pi-spin' : 'pi pi-save'"
    label="Save"
    [disabled]="isSaving"
    (click)="addProduct()"
  ></button>
</div>
