<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Stock take</h1>
    <div>
      <button
        *ngIf="!loadingStockTake && stockTake.status === 'open' && stockTake.areas.length"
        type="button"
        pButton pRipple
        class="p-button-outlined p-button-success p-mr-2"
        label="Complete stock take"
        [icon]="savingStockTake ? 'pi pi-spinner pi-spin' : 'pi pi-check'"
        [disabled]="savingStockTake"
        (click)="completeStockTake()"
      ></button>
      <button
        *ngIf="!loadingStockTake && stockTake.status === 'closed' && currentUser.type === 'admin'"
        type="button"
        pButton pRipple
        class="p-button-outlined p-button-success p-mr-2"
        label="Export"
        [icon]="savingStockTake || isExporting ? 'pi pi-spinner pi-spin' : 'pi pi-file-excel'"
        [disabled]="savingStockTake || isExporting"
        (click)="exportScannedStock()"
      ></button>
      <a
        [routerLink]="['/stock-takes', stockTakeId, 'compare']"
        *ngIf="!loadingStockTake && stockTake.status === 'closed' && currentUser.type === 'admin'"
        type="button"
        pButton pRipple
        class="p-button-outlined p-button-secondary p-mr-2"
        label="Compare"
        [icon]="savingStockTake ? 'pi pi-spinner pi-spin' : 'pi pi-list'"
        [class.p-disabled]="savingStockTake"
      ></a>
      <button
        *ngIf="!loadingStockTake && stockTake.status === 'closed' && currentUser.type === 'admin'"
        type="button"
        pButton pRipple
        class="p-button-outlined p-button-info p-mr-2"
        label="Re-open stock take"
        [icon]="savingStockTake ? 'pi pi-spinner pi-spin' : 'pi pi-replay'"
        [disabled]="savingStockTake"
        (click)="reopenStockTake()"
      ></button>
      <button
        *ngIf="!loadingStockTake && stockTake.status === 'open'"
        type="button"
        pButton pRipple
        class="p-button-outlined p-button-secondary"
        label="Add area"
        icon="pi pi-plus"
        (click)="showAddAreaDialog()"
      ></button>
      <a
        [routerLink]="['/stock-takes']"
        pButton pRipple
        class="p-button-outlined p-button-secondary"
        icon="pi pi-arrow-left"
        label="Back"
      ></a>
    </div>
  </div>
  <div class="p-d-flex p-jc-start p-ai-baseline">
    <h2 class="text-thin p-mr-5" *ngIf="!loadingStockTake">{{ stockTake.name }}</h2>
    <h3 class="text-thin" *ngIf="!loadingStockTake">{{ stockTake.stock_take_date | date : 'd LLL yyyy' }}</h3>
  </div>
  <p-divider></p-divider>
  <p-messages severity="warn" *ngIf="!loadingStockTake && stockTake.areas.length === 0">
    <ng-template pTemplate>
      There are no stock areas loaded.
    </ng-template>
  </p-messages>
  <div class="p-grid" *ngIf="!loadingStockTake && stockTake.areas.length">
    <div class="p-col-12 p-md-6" *ngFor="let area of stockTake.areas">
      <app-stock-area [stockArea]="area" [stockTakeStatus]="stockTake.status === 'open'"></app-stock-area>
    </div>
  </div>
</div>


<p-blockUI [blocked]="checkIfLoggedIn || loadingStockTake || isExporting">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
