<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Users</h1>
    <button pButton pRipple class="p-button-outlined p-button-success" icon="pi pi-plus" label="Add user" (click)="showAddDialog()"></button>
  </div>
  <p-divider></p-divider>
  <p-card>
    <p-messages severity="warn" *ngIf="users.length === 0 && !loadingUsers">
      <ng-template pTemplate>
        There are no users loaded.
      </ng-template>
    </p-messages>
    <div class="text-center" *ngIf="loadingUsers">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dt
      *ngIf="!loadingUsers && users.length > 0"
      [value]="users"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [rows]="25"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
      [globalFilterFields]="['name', 'email', 'type', 'company_name']"
      sortField="name"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id" style="width: 100px;" *ngIf="currentUser.type === 'employee'">
            #
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="email">
            Email address
            <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th pSortableColumn="company_name" *ngIf="currentUser.type === 'employee'">
            Company
            <p-sortIcon field="company_name"></p-sortIcon>
          </th>
          <th pSortableColumn="type">
            Type
            <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th pSortableColumn="date_modified">
            Last modified
            <p-sortIcon field="date_modified"></p-sortIcon>
          </th>
          <th class="text-right">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-u>
        <tr>
          <td *ngIf="currentUser.type === 'employee'">{{ u.id }}</td>
          <td>{{ u.name }}</td>
          <td [title]="u.email">{{ u.email }}</td>
          <td *ngIf="currentUser.type === 'employee'">{{ u.company_name }}</td>
          <td>
            <p-tag [value]="u.type | titlecase" [class]="u.type" [rounded]="true"></p-tag>
          </td>
          <td>{{ u.date_modified | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td class="text-right">
            <button
              *ngIf="currentUser.id !== u.id && u.type !== 'employee' && currentUser.type === 'employee'"
              type="button"
              pRipple pButton
              icon="pi pi-key"
              class="p-button-rounded p-button-secondary p-button-sm"
              pTooltip="Remote login"
              tooltipPosition="left"
              (click)="remoteLogin(u.id)"
            ></button>&nbsp;
            <button
              *ngIf="currentUser.id !== u.id"
              type="button"
              pRipple pButton
              icon="pi pi-pencil"
              class="p-button-rounded p-button-info p-button-sm"
              pTooltip="Edit user"
              tooltipPosition="left"
              (click)="showEditDialog(u)"
            ></button>&nbsp;
            <button
              *ngIf="currentUser.id !== u.id"
              type="button"
              pRipple pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              pTooltip="Delete user"
              tooltipPosition="left"
              (click)="deleteUser(u.id, $event)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search users">
          </span>
        </div>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmPopup></p-confirmPopup>

<p-blockUI [blocked]="checkIfLoggedIn || isLoggingInRemotely">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
