import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { CompanyModel } from '../../models/company.model';
import { HttpService } from '../../services/http.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserModel } from '../../models/user.model';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.css']
})
export class AddUserDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;
  @ViewChild('email') email: ElementRef<HTMLInputElement>;
  @ViewChild('password') password: ElementRef<HTMLInputElement>;

  loadingCompanies = true;

  companies: CompanyModel[] = [];

  selectedCompany: number;

  userTypes = [
    { name: 'Receiver', value: 'receiver' },
    { name: 'Admin', value: 'admin' },
    { name: 'Employee', value: 'employee' }
  ];

  selectedUserType = 'receiver';

  nameNotEmpty = true;
  emailNotEmpty = true;
  emailValid = true;
  passwordNotEmpty = true;
  companySelected = true;
  userIsEmployee = false;

  isSaving = false;

  currentUser: UserModel;

  constructor(
    private generalService: GeneralService,
    private httpService: HttpService,
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));
    if (this.currentUser.type !== 'employee') {
      this.selectedCompany = this.currentUser.company_id;
      this.userTypes.pop();
    }
    else {
      this.httpService.getCompanies().then((companies: CompanyModel[]) => {
        this.companies = companies;
        this.loadingCompanies = false;
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t fetch companies', detail: err.error.detail, life: 5000 });
      });
    }
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  validateEmail(): boolean {
    this.emailNotEmpty = this.email.nativeElement.value !== '';
    this.emailValid = this.emailNotEmpty ? this.generalService.validateEmail(this.email.nativeElement.value) : true;
    return this.emailValid && this.emailNotEmpty;
  }

  validatePassword(): boolean {
    this.passwordNotEmpty = this.password.nativeElement.value !== '';
    return this.passwordNotEmpty;
  }

  validateCompany(): boolean {
    this.companySelected = this.userIsEmployee || (this.selectedCompany !== null && typeof this.selectedCompany !== 'undefined');
    return this.companySelected;
  }

  checkUserTypeSelected(): void {
    this.userIsEmployee = this.selectedUserType === 'employee';
    if (this.userIsEmployee) {
      this.selectedCompany = null;
    }
  }

  addUser(): void {
    this.isSaving = true;

    let hasErrors = false;

    if (!this.validateName()) {
      hasErrors = true;
    }
    if (!this.validateEmail()) {
      hasErrors = true;
    }
    if (!this.validatePassword()) {
      hasErrors = true;
    }
    if (!this.validateCompany()) {
      hasErrors = true;
    }

    if (!hasErrors) {
      this.httpService.createUser(
        this.name.nativeElement.value,
        this.email.nativeElement.value,
        this.password.nativeElement.value,
        this.selectedCompany,
        this.selectedUserType
      ).then(result => {
        this.dialogRef.close(result);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t add user', detail: err.error.message, life: 5000 });
      });
    }
    else {
      this.isSaving = false;
    }
  }

}
