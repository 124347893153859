import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CompanyModel } from '../models/company.model';
import { UserModel } from '../models/user.model';
import { ProductModel } from '../models/product.model';
import { StockTakeModel } from '../models/stock-take.model';
import { StockAreaModel } from '../models/stock-area.model';
import { StockProductModel } from '../models/stock-product.model';
import { CookieService } from './cookie.service';
import { PurchaseOrderModel } from '../models/purchase-order.model';
import { PurchaseOrderProductModel } from '../models/purchase-order-product.model';
import { formatDate } from '@angular/common';
import { PurchaseOrderReceivedProductModel } from '../models/purchase-order-received-product.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public url = environment.apiUrl;

  authToken: string;
  authAdmin: string;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.authToken = this.cookieService.getCookie('adstockUserToken');
    this.authAdmin = this.cookieService.getCookie('adstockUserAdmin');
  }

  // Companies

  getCompanies(): Promise<CompanyModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'companies/', { headers }).toPromise().then((result: CompanyModel[]) => {
      return result;
    });
  }

  getCompany(id: number): Promise<CompanyModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'companies/' + id, { headers }).toPromise().then((result: CompanyModel) => {
      return result;
    });
  }

  async getCompanyName(id: number): Promise<string> {
    return this.getCompany(id).then(result => {
      return result.name;
    }).catch(() => {
      return 'error';
    });
  }

  createCompany(name: string): Promise<CompanyModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    const company: CompanyModel = { name };
    return this.http.post(this.url + 'companies/', company, { headers }).toPromise().then((result: CompanyModel) => {
      return result;
    });
  }

  updateCompany(company: CompanyModel): Promise<CompanyModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.patch(this.url + 'companies/', company, { headers }).toPromise().then((result: CompanyModel) => {
      return result;
    });
  }

  deleteCompany(id: number): Promise<CompanyModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'companies/' + id, { headers }).toPromise().then((result: CompanyModel) => {
      return result;
    });
  }

  // Users

  getUsersAsEmployee(): Promise<UserModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'users/', { headers }).toPromise().then((result: UserModel[]) => {
      return result;
    });
  }

  getUsersAsAdmin(id: number): Promise<UserModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'users/company/' + id, { headers }).toPromise().then((result: UserModel[]) => {
      return result;
    });
  }

  getUser(id: number): Promise<UserModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'users/' + id, { headers }).toPromise().then((result: UserModel) => {
      return result;
    });
  }

  createUser(name: string, email: string, password: string, companyId: number, type: string): Promise<UserModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    const user: UserModel = { name, email, password, company_id: companyId, type };
    return this.http.post(this.url + 'users/', user, { headers }).toPromise().then((result: UserModel) => {
      return result;
    });
  }

  updateUser(user: UserModel): Promise<UserModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.patch(this.url + 'users/', user, { headers }).toPromise().then((result: UserModel) => {
      return result;
    });
  }

  deleteUser(id: number): Promise<UserModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'users/' + id, { headers }).toPromise().then((result: UserModel) => {
      return result;
    });
  }

  // Products

  getProducts(id: number): Promise<ProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'products/company/' + id, { headers }).toPromise().then((result: ProductModel[]) => {
      return result;
    });
  }

  getProductCategories(id: number): Promise<string[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'products/categories/' + id, { headers }).toPromise().then((result: string[]) => {
      return result;
    });
  }

  bulkCreateProducts(products: ProductModel[]): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.post(this.url + 'products/bulk', products, { headers }).toPromise().then((result: boolean) => {
      return result;
    });
  }

  createProduct(name: string, stockCode: string, category: string, companyId: number, barcode: string = ''): Promise<ProductModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    const product: ProductModel = { company_id: companyId, category, stock_code: stockCode, barcode, name };
    return this.http.post(this.url + 'products', product, { headers }).toPromise().then((result: ProductModel) => {
      return result;
    });
  }

  updateProduct(product: ProductModel): Promise<ProductModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.patch(this.url + 'products/', product, { headers }).toPromise().then((result: ProductModel) => {
      return result;
    });
  }

  deleteProduct(id: number): Promise<ProductModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'products/' + id, { headers }).toPromise().then((result: ProductModel) => {
      return result;
    });
  }

  deleteAllCompanyProducts(id: number): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'products/all/' + id, { headers }).toPromise().then((result: boolean) => {
      return result;
    });
  }

  // Stock takes

  getStockTakes(id: number): Promise<StockTakeModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'stock-takes/company/' + id, { headers }).toPromise().then((result: StockTakeModel[]) => {
      return result;
    });
  }

  getStockTake(id: number): Promise<StockTakeModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'stock-takes/' + id, { headers }).toPromise().then((result: StockTakeModel) => {
      return result;
    });
  }

  getStockTakeProducts(id: number): Promise<StockProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(
      this.url + 'stock-takes/' + id + '/stock-take-products',
      { headers }
      ).toPromise().then((result: StockProductModel[]) => {
      return result;
    });
  }

  createStockTake(name: string, stockTakeDate: string, companyId: number, categories: any[] = []): Promise<StockTakeModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    const stockTake: StockTakeModel = { company_id: companyId, categories, name, status: 'open', stock_take_date: stockTakeDate };
    return this.http.post(this.url + 'stock-takes', stockTake, { headers }).toPromise().then((result: StockTakeModel) => {
      return result;
    });
  }

  updateStockTake(stockTake: StockTakeModel): Promise<StockTakeModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.patch(this.url + 'stock-takes', stockTake, { headers }).toPromise().then((result: StockTakeModel) => {
      return result;
    });
  }

  deleteStockTake(id: number): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'stock-takes/' + id, { headers }).toPromise().then((result: boolean) => {
      return result;
    });
  }

  // Stock area

  createStockArea(name: string, companyId: number, stockTakeId: number): Promise<StockAreaModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    const stockArea: StockAreaModel = { company_id: companyId, name };
    return this.http.post(
      this.url + 'stock-takes/' + stockTakeId + '/stock-areas',
      stockArea,
      { headers }
      ).toPromise().then((result: StockAreaModel) => {
      return result;
    });
  }

  getStockAreaProducts(id: number): Promise<StockProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(
      this.url + 'stock-takes/stock-areas/' + id + '/stock-area-products',
      { headers }
      ).toPromise().then((result: StockProductModel[]) => {
      return result;
    });
  }

  // Scan stock

  searchProduct(query: string, companyId: number): Promise<ProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(
      this.url + 'products/search/company/' + companyId + '/?query=' + encodeURIComponent(query),
      { headers }
    ).toPromise().then((result: ProductModel[]) => {
      return result;
    });
  }

  addStockProductWithBarcode(barcode: string, stockTakeId: number, stockAreaId: number): Promise<ProductModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.post(
      this.url + 'stock-takes/' + stockTakeId + '/stock-areas/' + stockAreaId + '/stock-products/barcode/' + encodeURIComponent(barcode),
      {},
      { headers }
    ).toPromise().then((result: StockProductModel) => {
      return result;
    });
  }

  addStockProductWithProduct(
    product: ProductModel,
    quantity: number,
    stockTakeId: number,
    stockAreaId: number
  ): Promise<StockProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.post(
      this.url + 'stock-takes/' + stockTakeId + '/stock-areas/' + stockAreaId + '/stock-products/product/' + product.id + '/' + quantity,
      {},
      { headers }
    ).toPromise().then((result: StockProductModel[]) => {
      return result;
    });
  }

  deleteStockProduct(id: number): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'stock-takes/stock-products/' + id, { headers }).toPromise().then((result: boolean) => {
      return result;
    });
  }

  // Purchase orders

  getPurchaseOrders(id: number): Promise<PurchaseOrderModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'purchase-orders/company/' + id, { headers }).toPromise().then((result: PurchaseOrderModel[]) => {
      return result;
    });
  }

  getPurchaseOrder(id: number): Promise<PurchaseOrderModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(this.url + 'purchase-orders/' + id, { headers }).toPromise().then((result: PurchaseOrderModel) => {
      return result;
    });
  }

  createPurchaseOrder(dateOrdered: Date, purchaseOrderNumber: string, supplier: string, companyId: number): Promise<PurchaseOrderModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    const purchaseOrder: PurchaseOrderModel = {
      company_id: companyId, supplier, purchase_order_number: purchaseOrderNumber, date_ordered: formatDate(dateOrdered, 'yyyy-LL-dd', 'en-ZA')
    };
    console.log(purchaseOrder);
    return this.http.post(
      this.url + 'purchase-orders',
      purchaseOrder,
      { headers }
    ).toPromise().then((result: PurchaseOrderModel) => {
      return result;
    });
  }

  updatePurchaseOrder(purchaseOrder: PurchaseOrderModel): Promise<PurchaseOrderModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.patch(this.url + 'purchase-orders', purchaseOrder, { headers }).toPromise().then((result: PurchaseOrderModel) => {
      return result;
    });
  }

  deletePurchaseOrder(id: number): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.delete(this.url + 'purchase-orders/' + id, { headers }).toPromise().then((result: boolean) => {
      return result;
    });
  }

  getPurchaseOrderProducts(id: number): Promise<PurchaseOrderProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(
      this.url + 'purchase-orders/' + id + '/products',
      { headers }
      ).toPromise().then((result: PurchaseOrderProductModel[]) => {
      return result;
    });
  }

  bulkCreatePurchaseOrderProducts(products: PurchaseOrderProductModel[], purchaseOrderId: number): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.post(
      this.url + 'purchase-orders/' + purchaseOrderId + '/products/bulk',
      products,
      { headers }
      ).toPromise().then((result: boolean) => {
      return result;
    });
  }

  addPurchaseOrderProductReceiveWithBarcode(barcode: string, purchaseOrderId: number): Promise<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.post(
      this.url + 'purchase-orders/' + purchaseOrderId + '/receive/barcode/' + encodeURIComponent(barcode),
      {},
      { headers }
    ).toPromise().then((result: boolean) => {
      return result;
    });
  }

  getPurchaseOrderReceivedProducts(id: number): Promise<PurchaseOrderReceivedProductModel[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    return this.http.get(
      this.url + 'purchase-orders/' + id + '/received-products',
      { headers }
    ).toPromise().then((result: PurchaseOrderReceivedProductModel[]) => {
      return result;
    });
  }

}
