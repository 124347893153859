import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-stock-take-dialog',
  templateUrl: './add-stock-take-dialog.component.html',
  styleUrls: ['./add-stock-take-dialog.component.css']
})
export class AddStockTakeDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;

  isSaving = false;
  loadingCategories = true;

  nameNotEmpty = true;

  stockTakeDate: Date;

  categories: any[] = [];

  selectedCategories: any[] = [];

  companyId: number;

  constructor(
    private httpService: HttpService,
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.stockTakeDate = new Date();

    this.companyId = this.dialogConfig.data.companyId;

    this.httpService.getProductCategories(this.companyId).then(result => {
      for (const i of result) {
        this.categories.push({ code: i, name: i });
      }
      this.loadingCategories = false;
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Couldn\'t load categories', detail: err.error.detail, life: 5000 });
      this.loadingCategories = false;
    });
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  addStockTake(): void {
    this.isSaving = true;

    if (this.validateName()) {
      const categories = [];
      for (const i of this.selectedCategories) {
        categories.push(i);
      }
      this.httpService.createStockTake(
        this.name.nativeElement.value,
        formatDate(this.stockTakeDate, 'yyyy-LL-dd', 'en-ZA'),
        this.companyId,
        categories
      ).then(stockTake => {
        if (stockTake) {
          this.dialogRef.close(true);
          this.router.navigate(['/stock-takes', stockTake.id]).then();
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Unable to add stock take', detail: err.error.detail, life: 5000 });
        this.isSaving = false;
      });
    }
    else {
      this.isSaving = false;
    }
  }

}
