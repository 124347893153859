<p class="adstock-lock-paragraph">
  Are you still logged in?
</p>
<p>
  If you cancel or if you close your browser, your session will be terminated.
</p>
<div class="text-right p-mt-4">
  <button type="button" pButton pRipple class="p-button-outlined p-button-danger" icon="pi pi-times" label="No" (click)="dialogRef.close()"></button>&nbsp;
  <button type="button" pButton pRipple class="p-button-outlined p-button-success" icon="pi pi-check" label="Yes" (click)="dialogRef.close(true)"></button>
</div>
