import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItemModel } from '../../navigation/menu-item.model';
import { UserModel } from '../../models/user.model';
import { PurchaseOrderModel } from '../../models/purchase-order.model';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { CookieService } from '../../services/cookie.service';
import { PurchaseOrderProductModel } from '../../models/purchase-order-product.model';
import { PurchaseOrderReceivedProductModel } from '../../models/purchase-order-received-product.model';
import { MessageService } from 'primeng/api';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-view-purchase-order',
  templateUrl: './view-purchase-order.component.html',
  styleUrls: ['./view-purchase-order.component.css']
})
export class ViewPurchaseOrderComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingPurchaseOrder = true;

  purchaseOrderId: number;
  purchaseOrder: PurchaseOrderModel;

  purchaseOrderProducts: PurchaseOrderProductModel[] = [];
  purchaseOrderReceivedProducts: PurchaseOrderReceivedProductModel[] = [];

  correct: any[] = [];
  notReceived: any[] = [];
  tooLow: any[] = [];
  tooHigh: any[] = [];
  notOrdered: any[] = [];
  notFound: any[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          this.route.params.subscribe(params => {
            this.purchaseOrderId = params.id;

            this.httpService.getPurchaseOrder(this.purchaseOrderId).then(purchaseOrder => {
              this.purchaseOrder = purchaseOrder;
              this.httpService.getPurchaseOrderProducts(this.purchaseOrderId).then(purchaseOrderProducts => {
                this.purchaseOrderProducts = purchaseOrderProducts;
                this.httpService.getPurchaseOrderReceivedProducts(this.purchaseOrderId).then(purchaseOrderReceivedProducts => {
                  this.purchaseOrderReceivedProducts = purchaseOrderReceivedProducts;
                  this.arrangePurchaseOrderProducts().then(() => {
                    this.loadingPurchaseOrder = false;
                    console.log(this.purchaseOrder);
                  });
                }).catch(err => {
                  this.messageService.add({
                    severity: 'error', summary: 'Couldn\'t fetch received purchase order products', detail: err.error.detail, life: 5000
                  });
                  this.loadingPurchaseOrder = false;
                });
              }).catch(err => {
                this.messageService.add({
                  severity: 'error', summary: 'Couldn\'t fetch purchase order products', detail: err.error.detail, life: 5000
                });
                this.loadingPurchaseOrder = false;
              });
            }).catch(err => {
              this.messageService.add({
                severity: 'error', summary: 'Couldn\'t fetch purchase order', detail: err.error.detail, life: 5000
              });
              this.loadingPurchaseOrder = false;
            });
          });

          if (this.currentUser.type === 'admin') {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders', routerLinkActiveOptions: false },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  async arrangePurchaseOrderProducts(): Promise<void> {
    this.purchaseOrderProducts.forEach(product => {
      if (product.quantity_ordered === product.quantity_received) {
        this.correct.push({
          name: product.name,
          stock_code: product.stock_code,
          barcode: product.barcode,
          quantity_ordered: product.quantity_ordered,
          quantity_received: product.quantity_received
        });
      }
      else if (product.quantity_ordered > product.quantity_received && product.quantity_received > 0) {
        this.tooLow.push({
          name: product.name,
          stock_code: product.stock_code,
          barcode: product.barcode,
          quantity_ordered: product.quantity_ordered,
          quantity_received: product.quantity_received
        });
      }
      else if (product.quantity_ordered > product.quantity_received && product.quantity_received === 0) {
        this.notReceived.push({
          name: product.name,
          stock_code: product.stock_code,
          barcode: product.barcode,
          quantity_ordered: product.quantity_ordered,
          quantity_received: product.quantity_received
        });
      }
      else if (product.quantity_ordered < product.quantity_received) {
        this.tooHigh.push({
          name: product.name,
          stock_code: product.stock_code,
          barcode: product.barcode,
          quantity_ordered: product.quantity_ordered,
          quantity_received: product.quantity_received
        });
      }
    });

    this.purchaseOrderReceivedProducts.forEach(receiveProduct => {
      if (typeof receiveProduct.related_product === 'undefined' || receiveProduct.related_product === null) {
        if (typeof this.notFound[receiveProduct.barcode] !== 'undefined') {
          this.notFound[receiveProduct.barcode] = {
            name: receiveProduct.name,
            stock_code: receiveProduct.stock_code,
            barcode: receiveProduct.barcode,
            quantity_ordered: 0,
            quantity_received: 0
          };
        }
        this.notFound[receiveProduct.barcode].quantity_received++;
      }
      else if (typeof receiveProduct.purchase_order_product === 'undefined' || receiveProduct.purchase_order_product === null) {
        if (typeof this.notOrdered[receiveProduct.barcode] !== 'undefined') {
          this.notOrdered[receiveProduct.barcode] = {
            name: receiveProduct.name,
            stock_code: receiveProduct.stock_code,
            barcode: receiveProduct.barcode,
            quantity_ordered: 0,
            quantity_received: 0
          };
        }
        this.notOrdered[receiveProduct.barcode].quantity_received++;
      }
    });
  }

}
