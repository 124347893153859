import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../navigation/menu-item.model';
import { UserModel } from '../models/user.model';
import { StockTakeModel } from '../models/stock-take.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../services/http.service';
import { GeneralService } from '../services/general.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddStockTakeDialogComponent } from './add-stock-take-dialog/add-stock-take-dialog.component';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-stock-takes',
  templateUrl: './stock-takes.component.html',
  styleUrls: ['./stock-takes.component.css']
})
export class StockTakesComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingStockTakes = true;

  stockTakes: StockTakeModel[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public httpService: HttpService,
    public generalService: GeneralService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          this.httpService.getStockTakes(this.currentUser.company_id).then(stockTakes => {
            this.stockTakes = stockTakes;
            this.loadingStockTakes = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Couldn\'t fetch stock takes', detail: err.error.detail, life: 5000 });
          });

          if (this.currentUser.type === 'admin') {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  showAddDialog(): void {
    const dialogRef = this.dialogService.open(AddStockTakeDialogComponent, {
      data: {
        companyId: this.currentUser.company_id
      },
      header: 'Add stock take',
      width: '50%'
    });
  }

  deleteStockTake(id: number, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this stock take?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingStockTakes = true;
        this.httpService.deleteStockTake(id).then(() => {
          this.httpService.getStockTakes(this.currentUser.company_id).then(stockTakes => {
            this.stockTakes = stockTakes;
            this.loadingStockTakes = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch stock takes', detail: err.error.detail, life: 5000 });
            this.loadingStockTakes = false;
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Couldn\'t delete stock take', detail: err.error.detail, life: 5000 });
          this.loadingStockTakes = false;
        });
      }
    });
  }

  completeStockTake(s: StockTakeModel, event): void {
    event.target.disabled = true;
    const stockTake: StockTakeModel = {
      id: s.id,
      name: s.name,
      status: 'closed'
    };
    this.httpService.updateStockTake(stockTake).then(result => {
      this.stockTakes.splice(this.stockTakes.indexOf(s), 1);
      this.stockTakes = [...this.stockTakes, result];
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to complete stock take', detail: err.error.detail, life: 5000 });
    });
  }

  reopenStockTake(s: StockTakeModel, event): void {
    event.target.disabled = true;
    const stockTake: StockTakeModel = {
      id: s.id,
      name: s.name,
      status: 'open'
    };
    this.httpService.updateStockTake(stockTake).then(result => {
      this.stockTakes.splice(this.stockTakes.indexOf(s), 1);
      this.stockTakes = [...this.stockTakes, result];
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to complete stock take', detail: err.error.detail, life: 5000 });
    });
  }

}
