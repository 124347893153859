<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Products</h1>
    <div *ngIf="!loadingProducts && currentUser.type === 'admin'">
      <a
        [routerLink]="['/products', 'import']"
        pButton pRipple
        class="p-button-outlined p-button-info"
        icon="pi pi-upload"
        label="Import products"
      ></a>&nbsp;
      <button
        pButton pRipple
        class="p-button-outlined p-button-success"
        icon="pi pi-plus"
        label="Add product"
        (click)="showAddDialog()"
      ></button>&nbsp;
      <button
        pButton pRipple
        class="p-button-outlined p-button-danger"
        icon="pi pi-trash"
        label="Delete all products"
        *ngIf="generalService.isRemotelyLoggedIn()"
        (click)="deleteAllProducts($event)"
      ></button>
    </div>
  </div>
  <p-divider></p-divider>
  <p-card>
    <p-messages severity="warn" *ngIf="products.length === 0 && !loadingProducts">
      <ng-template pTemplate>
        There are no products loaded.
      </ng-template>
    </p-messages>
    <div class="text-center" *ngIf="loadingProducts">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dt
      *ngIf="!loadingProducts && products.length > 0"
      [value]="products"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [rows]="25"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
      [globalFilterFields]="['name', 'stock_code', 'barcode', 'category']"
      sortField="name"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id" style="width: 100px;" *ngIf="currentUser.type === 'employee'">
            #
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            Product
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="stock_code">
            SKU
            <p-sortIcon field="stock_code"></p-sortIcon>
          </th>
          <th pSortableColumn="barcode">
            Barcode
            <p-sortIcon field="barcode"></p-sortIcon>
          </th>
          <th pSortableColumn="category">
            Category
            <p-sortIcon field="category"></p-sortIcon>
          </th>
          <th class="text-right" *ngIf="currentUser.type === 'admin'">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td *ngIf="currentUser.type === 'employee'">{{ product.id }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td>{{ product.barcode }}</td>
          <td>{{ product.category }}</td>
          <td class="text-right" *ngIf="currentUser.type === 'admin'">
            <button
              type="button"
              pRipple pButton
              icon="pi pi-pencil"
              class="p-button-rounded p-button-info p-button-sm"
              pTooltip="Edit product"
              tooltipPosition="left"
              (click)="showEditDialog(product)"
            ></button>&nbsp;
            <button
              type="button"
              pRipple pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              pTooltip="Delete product"
              tooltipPosition="left"
              (click)="deleteProduct(product.id, $event)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search products">
          </span>
        </div>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmPopup></p-confirmPopup>

<p-blockUI [blocked]="checkIfLoggedIn">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
