<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Import products</h1>
  </div>
  <p-divider></p-divider>
  <p-card>
    <p>Please choose your exported list <code>.csv</code> file. Here is an example of what the file should look like in order to import correctly.</p>
    <p-table [value]="exampleProducts">
      <ng-template pTemplate="header">
        <tr>
          <th>Product name</th>
          <th>SKU</th>
          <th>Barcode</th>
          <th>Category</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-exampleProduct>
        <tr>
          <td>{{ exampleProduct.name }}</td>
          <td>{{ exampleProduct.stock_code }}</td>
          <td>{{ exampleProduct.barcode }}</td>
          <td>{{ exampleProduct.category }}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-messages severity="info">
      <ng-template pTemplate="">
        Please note that the first row will not be imported and will be treated as the column headings. The columns should be in this exact order for the import to succeed.
      </ng-template>
    </p-messages>
    <p-divider></p-divider>
    <p-fileUpload name="files[]" accept=".csv" [customUpload]="true" (uploadHandler)="uploadProducts($event.files)"></p-fileUpload>
  </p-card>
</div>


<p-blockUI [blocked]="checkIfLoggedIn || isUploading">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
