<p-card>
  <p-header>
    <div class="p-d-flex p-jc-between p-ai-center">
      <div class="area-name">{{ stockArea.name }}</div>
      <a
        *ngIf="stockTakeStatus"
        [routerLink]="['/stock-takes', stockArea.stock_take_id, 'scan-stock', stockArea.id]"
        pButton pRipple
        class="p-button-outlined p-button-secondary p-button-sm p-mr-2"
        icon="pi pi-plus"
        label="Scan products"
      ></a>
    </div>
  </p-header>
  <p-progressSpinner *ngIf="isLoadingProducts"></p-progressSpinner>
  <p-messages severity="warn" *ngIf="!isLoadingProducts && consolidatedProducts.length === 0">
    <ng-template pTemplate>
      There are no products scanned yet...
    </ng-template>
  </p-messages>
  <p-table
    #dt
    *ngIf="!isLoadingProducts && consolidatedProducts.length > 0"
    [value]="consolidatedProducts"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [rows]="10"
    [globalFilterFields]="['name', 'stock_code', 'barcode']"
    sortField="name"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Product
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="stock_code">
          SKU
          <p-sortIcon field="stock_code"></p-sortIcon>
        </th>
        <th pSortableColumn="barcode">
          Barcode
          <p-sortIcon field="barcode"></p-sortIcon>
        </th>
        <th pSortableColumn="quantity" class="text-right">
          Quantity
          <p-sortIcon field="quantity"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product.name }}</td>
        <td>{{ product.stock_code }}</td>
        <td>{{ product.barcode }}</td>
        <td class="text-right">{{ product.quantity }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search products">
          </span>
      </div>
    </ng-template>
  </p-table>
</p-card>
