import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { HttpService } from '../../services/http.service';
import { UserModel } from '../../models/user.model';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.css']
})
export class AddProductDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;
  @ViewChild('stockCode') stockCode: ElementRef<HTMLInputElement>;
  @ViewChild('barcode') barcode: ElementRef<HTMLInputElement>;
  @ViewChild('category') category: ElementRef<HTMLInputElement>;

  nameNotEmpty = true;
  stockCodeNotEmpty = true;
  categoryNotEmpty = true;

  isSaving = false;

  currentUser: UserModel;

  constructor(
    public dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpService: HttpService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  validateStockCode(): boolean {
    this.stockCodeNotEmpty = this.stockCode.nativeElement.value !== '';
    return this.stockCodeNotEmpty;
  }

  validateCategory(): boolean {
    this.categoryNotEmpty = this.category.nativeElement.value !== '';
    return this.categoryNotEmpty;
  }

  addProduct(): void {
    this.isSaving = true;

    let hasErrors = false;

    if (!this.validateName()) {
      hasErrors = true;
    }
    if (!this.validateStockCode()) {
      hasErrors = true;
    }
    if (!this.validateCategory()) {
      hasErrors = true;
    }

    if (!hasErrors) {
      this.httpService.createProduct(
        this.name.nativeElement.value,
        this.stockCode.nativeElement.value,
        this.category.nativeElement.value,
        this.currentUser.company_id,
        this.barcode.nativeElement.value
      ).then(result => {
        this.dialogRef.close(result);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t add product', detail: err.error.detail, life: 5000 });
        this.isSaving = false;
      });
    }
    else {
      this.isSaving = false;
    }
  }

}
