import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { HttpService } from './services/http.service';
import { UserModel } from './models/user.model';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { GeneralService } from './services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private primengConfig: PrimeNGConfig,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

}
