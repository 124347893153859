import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { HttpService } from './services/http.service';
import { AuthService } from './services/auth.service';
import { GeneralService } from './services/general.service';
import { ExcelService } from './services/excel.service';

import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';

import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { TagModule } from 'primeng/tag';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { CompaniesComponent } from './companies/companies.component';
import { UsersComponent } from './users/users.component';
import { ProductsComponent } from './products/products.component';
import { StockTakesComponent } from './stock-takes/stock-takes.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { AddCompanyDialogComponent } from './companies/add-company-dialog/add-company-dialog.component';
import { EditCompanyDialogComponent } from './companies/edit-company-dialog/edit-company-dialog.component';
import { FormsModule } from '@angular/forms';
import { AddUserDialogComponent } from './users/add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from './users/edit-user-dialog/edit-user-dialog.component';
import { ImportProductsComponent } from './products/import-products/import-products.component';
import { AddProductDialogComponent } from './products/add-product-dialog/add-product-dialog.component';
import { EditProductDialogComponent } from './products/edit-product-dialog/edit-product-dialog.component';
import { AddStockTakeDialogComponent } from './stock-takes/add-stock-take-dialog/add-stock-take-dialog.component';
import { StockTakeDetailsComponent } from './stock-takes/stock-take-details/stock-take-details.component';
import { LockComponent } from './auth/lock/lock.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { StockAreaComponent } from './stock-takes/stock-take-details/stock-area/stock-area.component';
import { AddStockAreaDialogComponent } from './stock-takes/stock-take-details/add-stock-area-dialog/add-stock-area-dialog.component';
import { ScanStockComponent } from './stock-takes/stock-take-details/scan-stock/scan-stock.component';
import { CookieService } from './services/cookie.service';
import { CompareStockTakeComponent } from './stock-takes/stock-take-details/compare-stock-take/compare-stock-take.component';
import { AddPurchaseOrderDialogComponent } from './purchase-orders/add-purchase-order-dialog/add-purchase-order-dialog.component';
import { ReceivePurchaseOrderComponent } from './purchase-orders/receive-purchase-order/receive-purchase-order.component';
import { ViewPurchaseOrderComponent } from './purchase-orders/view-purchase-order/view-purchase-order.component';
import { PrintReceivedPurchaseOrderComponent } from './purchase-orders/view-purchase-order/print-received-purchase-order/print-received-purchase-order.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    NavigationComponent,
    CompaniesComponent,
    UsersComponent,
    ProductsComponent,
    StockTakesComponent,
    PurchaseOrdersComponent,
    AddCompanyDialogComponent,
    EditCompanyDialogComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    ImportProductsComponent,
    AddProductDialogComponent,
    EditProductDialogComponent,
    AddStockTakeDialogComponent,
    StockTakeDetailsComponent,
    LockComponent,
    ProfileComponent,
    StockAreaComponent,
    AddStockAreaDialogComponent,
    ScanStockComponent,
    CompareStockTakeComponent,
    AddPurchaseOrderDialogComponent,
    ReceivePurchaseOrderComponent,
    ViewPurchaseOrderComponent,
    PrintReceivedPurchaseOrderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    RippleModule,
    DividerModule,
    BlockUIModule,
    ProgressSpinnerModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    TooltipModule,
    DynamicDialogModule,
    FormsModule,
    ConfirmPopupModule,
    DropdownModule,
    PasswordModule,
    TagModule,
    FileUploadModule,
    CalendarModule,
    MultiSelectModule,
    AutoCompleteModule
  ],
  providers: [MessageService, DialogService, ConfirmationService, HttpService, AuthService, GeneralService, CookieService, ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
