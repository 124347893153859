<div class="p-fluid p-field p-mb-5 p-mt-5">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="pi pi-map-marker"></i>
    </span>
    <div class="p-float-label">
      <input type="text" pInputText id="name" class="p-inputtext-lg" [class.p-invalid]="!nameNotEmpty" #name (keyup.enter)="addStockArea()" (change)="validateName()" autofocus>
      <label for="name">Area name</label>
    </div>
  </div>
  <small class="p-invalid" *ngIf="!nameNotEmpty">Area name is required</small>
</div>
<div class="text-right">
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving? 'pi pi-spinner pi-spin' : 'pi pi-times'"
    label="Cancel"
    class="p-button-outlined p-button-secondary"
    [disabled]="isSaving"
    (click)="dialogRef.close()"
  ></button>&nbsp;
  <button
    type="button"
    pButton pRipple
    [icon]="isSaving ? 'pi pi-spinner pi-spin' : 'pi pi-save'"
    label="Save"
    [disabled]="isSaving"
    (click)="addStockArea()"
  ></button>
</div>
