<p>Upload the exported <code>.xlsx</code> file containing the purchase order details.</p>
<p-fileUpload name="files[]" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" [customUpload]="true" (uploadHandler)="addPurchaseOrder($event.files)"></p-fileUpload>
<div class="p-mt-5 text-right">
  <button
    type="button"
    pButton pRipple
    class="p-button-secondary p-button-outlined"
    icon="pi pi-times"
    label="Cancel"
    (click)="dialogRef.close()"
  ></button>
</div>

<p-blockUI [blocked]="isUploading">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
