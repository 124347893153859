<app-navigation [menuItems]="menuItems"></app-navigation>
<div class="container-fluid">
  <div class="p-d-flex p-jc-between p-ai-baseline">
    <h1>Scan stock</h1>
    <a
      pButton pRipple
      class="p-button-outlined p-button-secondary"
      icon="pi pi-arrow-left"
      label="Back"
      [routerLink]="['/stock-takes', stockTakeId]"
    ></a>
  </div>
  <p-divider></p-divider>
  <p-card>
    <div class="p-grid">
      <div class="p-col-12 p-md-4 p-fluid">
        <div class="p-float-label">
          <input type="text" pInputText id="barcode" #barcode (keyup.enter)="findAndAddProduct($event)" (keydown.tab)="findAndAddProduct($event)">
          <label for="barcode">Scan or enter barcode</label>
        </div>
      </div>
      <div class="p-col-12 p-md-4 p-ai-center p-d-flex">
        <button
          type="button"
          pButton pRipple
          label="Find & add"
          [disabled]="isAdding"
          [icon]="isAdding ? 'pi pi-spinner pi-spin' : 'pi pi-search-plus'"
          class="p-button-outlined"
          (click)="findAndAddProduct()"
        ></button>
        <span class="p-ml-2 p-mr-2">or</span>
        <button
          type="button"
          pButton pRipple
          label="Search manually"
          [disabled]="isAdding"
          [icon]="isAdding ? 'pi pi-spinner pi-spin' : 'pi pi-search'"
          class="p-button-outlined p-button-secondary"
          (click)="toggleAddManually()"
        ></button>
      </div>
    </div>
    <div class="p-grid p-mt-3" *ngIf="addManually">
      <div class="p-col-12 p-md-4 p-fluid">
        <div class="p-float-label">
          <p-autoComplete [(ngModel)]="searchedProduct" [suggestions]="searchedProducts" field="name" [inputId]="'search'" (completeMethod)="searchProducts($event)"></p-autoComplete>
          <label for="search">Search products</label>
        </div>
      </div>
      <div class="p-col-12 p-md-4">
        <div class="p-float-label">
          <input type="number" pInputText value="1" id="quantity" #quantity>
          <label for="quantity">Quantity</label>&nbsp;
          <button
            type="button"
            pButton pRipple
            class="p-button-outlined"
            [icon]="isAdding ? 'pi pi-spinner pi-spin' : 'pi pi-plus'"
            [disabled]="isAdding"
            label="Add"
            (click)="addProduct()"
          ></button>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <p-messages severity="warn" *ngIf="stockProducts.length === 0 && !loadingStockProducts">
      <ng-template pTemplate>
        There are no products scanned.
      </ng-template>
    </p-messages>
    <div class="text-center" *ngIf="loadingStockProducts">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table
      #dt
      *ngIf="!loadingStockProducts && stockProducts.length > 0"
      [value]="stockProducts"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [rows]="25"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} stock products"
      [globalFilterFields]="['name', 'stock_code', 'barcode']"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Product
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="stock_code">
            SKU
            <p-sortIcon field="stock_code"></p-sortIcon>
          </th>
          <th pSortableColumn="barcode">
            Barcode
            <p-sortIcon field="barcode"></p-sortIcon>
          </th>
          <th class="text-right">Qty.</th>
          <th pSortableColumn="date_created">
            Scanned
            <p-sortIcon field="date_created"></p-sortIcon>
          </th>
          <th class="text-right">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.name }}</td>
          <td>{{ product.stock_code }}</td>
          <td>{{ product.barcode }}</td>
          <td class="text-right">1</td>
          <td>{{ product.date_created | date : 'dd/LL/yyyy HH:mm:ss' }}</td>
          <td class="text-right" *ngIf="currentUser.type === 'admin'">
            <button
              *ngIf="currentUser.type === 'admin'"
              type="button"
              pRipple pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              pTooltip="Remove product"
              tooltipPosition="left"
              (click)="removeProduct(product.id, $event)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search products">
          </span>
        </div>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmPopup></p-confirmPopup>

<p-blockUI [blocked]="checkIfLoggedIn">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
