import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-company-dialog',
  templateUrl: './add-company-dialog.component.html',
  styleUrls: ['./add-company-dialog.component.css']
})
export class AddCompanyDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;

  nameNotEmpty = true;

  isSaving = false;

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  addCompany(): void {
    this.isSaving = true;

    if (this.validateName()) {
      this.httpService.createCompany(this.name.nativeElement.value).then(result => {
        this.dialogRef.close(result);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t add company', detail: err.error.message, life: 5000 });
      });
    }
    else {
      this.isSaving = false;
    }
  }

}
