<div class="container">
  <div class="p-grid auth p-mt-4">
    <div class="p-col-12 p-md-offset-3 p-md-6 p-lg-offset-4 p-lg-4">
      <p-card header="AdStock" subheader="Login">
        <div class="p-fluid p-field p-mt-5">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-envelope"></i>
            </span>
            <div class="p-float-label">
              <input type="email" pInputText id="email" class="p-inputtext-lg" [class.p-invalid]="!emailValid || !emailNotEmpty" #email (keyup.enter)="login()" (change)="validateEmail()">
              <label for="email">Email address</label>
            </div>
          </div>
          <small class="p-invalid" *ngIf="!emailNotEmpty">Email address is required</small>
          <small class="p-invalid" *ngIf="!emailValid">Email address is invalid (e.g. someone@example.com)</small>
        </div>
        <div class="p-fluid p-field p-mt-5">
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-key"></i>
          </span>
            <div class="p-float-label">
              <input type="password" pInputText id="password" class="p-inputtext-lg" [class.p-invalid]="!passwordNotEmpty" #password (keyup.enter)="login()" (change)="validatePassword()">
              <label for="password">Password</label>
            </div>
          </div>
          <small class="p-invalid" *ngIf="!passwordNotEmpty">Password is required</small>
        </div>
        <div class="p-fluid p-field p-mt-5">
          <button
            type="button"
            pButton
            pRipple
            class="p-button-lg p-button-rounded"
            label="Login"
            [icon]="isLoggingIn? 'pi pi-spinner pi-spin' : 'pi pi-sign-in'"
            [disabled]="isLoggingIn"
            (click)="login()"
            iconPos="right"
          ></button>
        </div>
        <div class="p-mt-3 text-center">
          <a [routerLink]="['/forgot-password']">Forgot your password?</a>
        </div>
      </p-card>
    </div>
  </div>
</div>

<p-blockUI [blocked]="checkIfLoggedIn">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
