import { Component, Input, OnInit } from '@angular/core';
import { MenuItemModel } from './menu-item.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  @Input() menuItems: MenuItemModel[];

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
