import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MenuItemModel } from '../navigation/menu-item.model';
import { UserModel } from '../models/user.model';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/companies'], icon: 'pi pi-map-marker', label: 'Companies' },
            { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
          ];
        }
        else if (this.currentUser.type === 'admin') {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
            { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
            { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
            { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
          ];
        }
        else {
          this.menuItems = [
            { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
            { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
            { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
            { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
          ];
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

}
