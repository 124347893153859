import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from '../navigation/menu-item.model';
import { UserModel } from '../models/user.model';
import { ProductModel } from '../models/product.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../services/http.service';
import { DialogService } from 'primeng/dynamicdialog';
import { GeneralService } from '../services/general.service';
import { AddProductDialogComponent } from './add-product-dialog/add-product-dialog.component';
import { EditProductDialogComponent } from './edit-product-dialog/edit-product-dialog.component';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingProducts = true;

  products: ProductModel[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public httpService: HttpService,
    public generalService: GeneralService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          this.httpService.getProducts(this.currentUser.company_id).then(products => {
            this.products = products;
            this.loadingProducts = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Couldn\'t fetch products', detail: err.error.detail, life: 5000 });
          });

          if (this.currentUser.type === 'admin') {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  showAddDialog(): void {
    const dialogRef = this.dialogService.open(AddProductDialogComponent, {
      header: 'Add product',
      width: '50%'
    });
    dialogRef.onClose.subscribe((product: ProductModel) => {
      if (product) {
        this.products = [...this.products, product];
      }
    });
  }

  showEditDialog(product: ProductModel): void {
    const dialogRef = this.dialogService.open(EditProductDialogComponent, {
      data: {
        product
      },
      header: 'Edit product',
      width: '50%'
    });
  }

  deleteProduct(id: number, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this product?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingProducts = true;
        this.httpService.deleteProduct(id).then(() => {
          this.httpService.getProducts(this.currentUser.company_id).then(products => {
            this.products = products;
            this.loadingProducts = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch products', detail: err.error.detail, life: 5000 });
            this.loadingProducts = false;
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Couldn\'t delete product', detail: err.error.detail, life: 5000 });
          this.loadingProducts = false;
        });
      }
    });
  }

  deleteAllProducts(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete ALL products? This cannot be undone.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingProducts = true;
        this.httpService.deleteAllCompanyProducts(this.currentUser.company_id).then(() => {
          this.httpService.getProducts(this.currentUser.company_id).then(products => {
            this.products = products;
            this.loadingProducts = false;
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Unable to fetch products', detail: err.error.detail, life: 5000 });
            this.loadingProducts = false;
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Unable to delete all products', detail: err.error.detail, life: 5000 });
          this.loadingProducts = false;
        });
      }
    });
  }

}
