import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanyModel } from '../../models/company.model';

@Component({
  selector: 'app-edit-company-dialog',
  templateUrl: './edit-company-dialog.component.html',
  styleUrls: ['./edit-company-dialog.component.css']
})
export class EditCompanyDialogComponent implements OnInit {

  @ViewChild('name') name: ElementRef<HTMLInputElement>;

  nameNotEmpty = true;

  isSaving = false;

  company: CompanyModel;
  private backupCompany: CompanyModel;

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.company = this.dialogConfig.data.company;
    this.backupCompany = Object.assign({}, this.company);

    this.dialogRef.onClose.subscribe(result => {
      if (typeof result === 'undefined' || result !== true) {
        this.company.name = this.backupCompany.name;
      }
    });
  }

  validateName(): boolean {
    this.nameNotEmpty = this.name.nativeElement.value !== '';
    return this.nameNotEmpty;
  }

  updateCompany(): void {
    this.isSaving = true;

    if (this.validateName()) {
      const companyUpdate: CompanyModel = {
        name: this.company.name,
        id: this.company.id
      };

      this.httpService.updateCompany(companyUpdate).then(result => {
        this.company.date_modified = result.date_modified;
        this.dialogRef.close(true);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Couldn\'t update company', detail: err.error.detail, life: 5000 });
        this.isSaving = false;
      });
    }
    else {
      this.isSaving = false;
    }

  }

}
