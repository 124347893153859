import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItemModel } from '../../../navigation/menu-item.model';
import { UserModel } from '../../../models/user.model';
import { StockProductModel } from '../../../models/stock-product.model';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpService } from '../../../services/http.service';
import { ProductModel } from '../../../models/product.model';
import { CookieService } from '../../../services/cookie.service';
import { typeCheckFilePath } from '@angular/compiler-cli/src/ngtsc/typecheck';

@Component({
  selector: 'app-scan-stock',
  templateUrl: './scan-stock.component.html',
  styleUrls: ['./scan-stock.component.css']
})
export class ScanStockComponent implements OnInit {

  @ViewChild('barcode') barcode: ElementRef<HTMLInputElement>;
  @ViewChild('quantity') quantity: ElementRef<HTMLInputElement>;

  menuItems: MenuItemModel[];

  checkIfLoggedIn = true;

  currentUser: UserModel;

  loadingStockProducts = true;

  isAdding = false;

  addManually = false;
  searchedProduct: ProductModel;
  searchedProducts: ProductModel[];

  stockTakeId: number;
  stockAreaId: number;

  stockProducts: StockProductModel[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(result => {
      if (!result) {
        this.router.navigate(['/login']).then();
      }
      else {
        this.currentUser = JSON.parse(this.cookieService.getCookie('adstockUser'));

        if (this.currentUser.type === 'employee') {
          this.messageService.add({
            severity: 'warn', summary: 'Unauthorized', detail: 'You are not authorized to access this page.', life: 2000
          });
          this.router.navigate(['/dashboard']).then();
        }
        else {
          this.route.params.subscribe(params => {
            this.stockAreaId = params.areaId;
            this.stockTakeId = params.id;

            this.httpService.getStockAreaProducts(this.stockAreaId).then(stockProducts => {
              this.stockProducts = stockProducts;
              this.loadingStockProducts = false;
            }).catch(err => {
              this.messageService.add({
                severity: 'error', summary: 'Couldn\'t fetch stock products', detail: err.error.detail, life: 5000
              });
            });
          });

          if (this.currentUser.type === 'admin') {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes', routerLinkActiveOptions: false },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' },
              { routerLink: ['/users'], icon: 'pi pi-users', label: 'Users' }
            ];
          }
          else {
            this.menuItems = [
              { routerLink: ['/'], icon: 'pi pi-home', label: 'Dashboard' },
              { routerLink: ['/products'], icon: 'pi pi-tags', label: 'Products' },
              { routerLink: ['/stock-takes'], icon: 'pi pi-list', label: 'Stock Takes' },
              { routerLink: ['/purchase-orders'], icon: 'pi pi-file', label: 'Purchase Orders' }
            ];
          }
        }
        this.checkIfLoggedIn = false;
      }
    });
  }

  toggleAddManually(): void {
    this.addManually = !this.addManually;
  }

  searchProducts(event: any): void {
    this.httpService.searchProduct(event.query, this.currentUser.company_id).then(results => {
      this.searchedProducts = results;
    }).catch(() => {
      this.searchedProducts = [];
    });
  }

  findAndAddProduct(event: Event = null): void {
    if (typeof event !== 'undefined' && event !== null) {
      event.preventDefault();
    }
    this.isAdding = true;
    if (this.barcode.nativeElement.value === '') {
      this.isAdding = false;
      this.barcode.nativeElement.focus();
      return;
    }

    this.httpService.addStockProductWithBarcode(this.barcode.nativeElement.value, this.stockTakeId, this.stockAreaId).then(stockProduct => {
      this.stockProducts = [stockProduct, ...this.stockProducts];
      this.isAdding = false;
      this.addManually = false;
      this.barcode.nativeElement.value = '';
      this.barcode.nativeElement.focus();
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to scan product', detail: err.error.detail, life: 5000 });
      this.barcode.nativeElement.focus();
      this.isAdding = false;
    });
  }

  addProduct(): void {
    this.isAdding = true;
    if (typeof this.searchedProduct === 'undefined' || this.searchedProduct === null) {
      this.isAdding = false;
      return;
    }

    const quantity = this.quantity.nativeElement.value !== '' ? parseInt(this.quantity.nativeElement.value, 10) : 1;

    this.httpService.addStockProductWithProduct(this.searchedProduct, quantity, this.stockTakeId, this.stockAreaId).then(stockProducts => {
      this.stockProducts = [...stockProducts, ...this.stockProducts];
      this.isAdding = false;
      this.addManually = false;
      this.searchedProduct = null;
      this.barcode.nativeElement.value = '';
      this.barcode.nativeElement.focus();
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Unable to scan product', detail: err.error.detail, life: 5000 });
      this.barcode.nativeElement.focus();
    });
  }

  removeProduct(id: number, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to remove this product?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingStockProducts = true;
        this.httpService.deleteStockProduct(id).then(() => {
          this.httpService.getStockAreaProducts(this.stockAreaId).then(stockProducts => {
            this.stockProducts = stockProducts;
            this.loadingStockProducts = false;
          }).catch(err => {
            this.messageService.add({
              severity: 'error', summary: 'Unable to fetch scanned products', detail: err.error.detail, life: 5000
            });
            this.loadingStockProducts = false;
          });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Couldn\'t remove scanned product', detail: err.error.detail, life: 5000 });
          this.loadingStockProducts = false;
        });
      }
    });
  }

}
